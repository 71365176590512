import { TGoogleRecaptchaInstance } from './GoogleRecaptcha';

/**
 * @description This class is not intended to have a separate DOM representation.
 *
 * @param AjaxForm Base widget for extending
 * @returns AjaxFormWithRecaptcha Form widget
 */
function AjaxFormWithRecaptchaClassCreator(AjaxForm: import('widgets/forms/AjaxForm').TAjaxForm) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AjaxFormWithRecaptcha
     * @augments AjaxForm
     * @classdesc Represents AjaxFormWithRecaptcha component with next features:
     * 1. Allows to execute Google Recaptcha on form submit
     * 2. Allows to reset Google Recaptcha after submission
     * @property {boolean} data-recaptcha-enabled - Determines if Recaptcha functionality enabled
     */
    class AjaxFormWithRecaptcha extends AjaxForm {
        prefs() {
            return {
                recaptchaEnabled: false,
                recaptchaWidgetId: 'googleRecaptcha',
                ...super.prefs()
            };
        }

        /**
         * @description Handles submit Form
         * @returns Promise object represents server response for shipping methods updating
         */
        handleSubmit() {
            if (!this.prefs().recaptchaEnabled) {
                return super.handleSubmit();
            }

            const submissionResult = this.getById(this.prefs().recaptchaWidgetId, (recaptcha: TGoogleRecaptchaInstance) => {
                return recaptcha.executeRecaptcha()
                    .then((token) => {
                        if (!token) {
                            return Promise.resolve(null);
                        }

                        return super.handleSubmit();
                    })
                    .catch(this.onError.bind(this));
            });

            return submissionResult || Promise.resolve(null);
        }

        /**
         * @description Changes Form state after submission
         */
        afterSubmission() {
            if (this.prefs().recaptchaEnabled) {
                this.getById(this.prefs().recaptchaWidgetId, (recaptcha: TGoogleRecaptchaInstance) => {
                    recaptcha.callIfExists('onRecaptchaReset');
                });
            }

            super.afterSubmission();
        }
    }

    return AjaxFormWithRecaptcha;
}

export type TAjaxFormWithRecaptcha = ReturnType<typeof AjaxFormWithRecaptchaClassCreator>;

export type TAjaxFormWithRecaptchaInstance = InstanceType<TAjaxFormWithRecaptcha>;

export default AjaxFormWithRecaptchaClassCreator;

import { getContentByUrl, getJSONByUrl } from 'widgets/toolbox/ajax';

import { TEmitBusEvent } from 'widgets/global/EmitBusEvent';

/**
 * @description Base GlobalModal implementation
 * @param Modal Base widget for extending
 * @returns GlobalModal class
 */
function GlobalModalClassCreator(Modal: import('widgets/global/Modal').TModal) {
    /**
     * @category widgets
     * @subcategory global
     * @class GlobalModal
     * @augments Modal
     * @classdesc Global Modal component. Allows different modals to be triggered by firing an event {@link module#dialog:dialogshow}.
     * <br>Was implemented to get rid from excessive duplicated popups markup in product lists etc.
     * <br>Also allow standalone components/link to trigger modal by using {@link EmitBusEvent} widget.
     * <br>In general could be triggered either programmatically by firing an event, or using {@link EmitBusEvent} widget in DOM markup.
     * <br>Allows: add classes to container; render modal heading; render modal action buttons; render content either via URL, or from string.
     * @property {string} data-widget - Widget name `globalModal`
     * @property {string} data-disable-rendering - property should be set to `false` to allow rendering inside popup template.
     * @example <caption>Global Modal widget markup</caption>
     * <div
     *     data-widget="globalModal"
     *     data-disable-rendering="false"
     *     data-accessibility-alerts='{
     *         "dialogContentLoaded": "${Resource.msg('alert.dialogContentLoaded', 'global', null)}"
     *     }'
     * >
     *     <div
     *         class="b-dialog"
     *         data-ref="container"
     *         hidden="hidden"
     *         data-label-loading="${Resource.msg('common.loading', 'common', '')}"
     *     >
     *         <div
     *             class="b-dialog-window"
     *             role="dialog"
     *             data-ref="dialog"
     *             aria-modal="true"
     *             aria-label="${Resource.msg('common.loading', 'common', '')}"
     *         >
     *             <div class="b-dialog-header">
     *                 <isinclude template="components/modal/closeButton">
     *             </div>
     *             <div
     *                 class="b-dialog-body b-user_content"
     *                 data-ref="content"
     *             ></div>
     *         </div>
     *     </div>
     *     <script type="template/mustache" data-ref="template">
     *         {{^url}}
     *             <div class="b-dialog" data-ref="container">
     *         {{/url}}
     *         <div
     *             class="b-dialog-window"
     *             role="dialog"
     *             data-ref="dialog"
     *             aria-modal="true"
     *             title="{{headerText}}"
     *             aria-label="{{ariaLabel}}"
     *         >
     *             <div class="b-dialog-header">
     *                 ... header content
     *             </div>
     *             <div class="b-dialog-body b-user_content" data-ref="content">
     *                 ... dialog content
     *             </div>
     *             <div class="b-dialog-footer m-actions">
     *                 ... action buttons content
     *             </div>
     *         </div>
     *         {{^url}}
     *             </div>
     *         {{/url}}
     *     </script>
     * </div>
     *
     * @example <caption>Global Modal widget triggering via DOM</caption>
     * <button
     *     class="b-promotion-details"
     *     type="button"
     *     aria-describedby="promotions-${promotion.id}"
     *     data-widget="emitBusEvent"
     *     data-bus-event-type="dialogshow"
     *     data-event-click.prevent="emitBusEvent"
     *     data-tau="promotion_details_cta"
     *     data-modal-config='{
     *         "content": "${promotion.detailsJSONSafe}",
     *         "attributes": {
     *             "data-tau": "promotion_details_dialog"
     *         },
     *         "ariaLabel": "${Resource.msg('promotion.details.label','product',null)}"
     *     }'
     * >
     *     ${Resource.msg('promotion.details','product',null)}
     * </button>
     *
     * @example <caption>Global Modal widget triggering via code in {@link ProductTile} widget (synchronous operation)</caption>
     * ...
     * showQuickView(button) {
     *     this.eventBus().emit('dialogshow', {
     *         url: button.data('url'),
     *         wrapperClasses: 'm-quick_view',
     *         ariaLabel: this.prefs().productName,
     *         attributes: {
     *             'data-tau-unique': 'quick_view_dialog'
     *         }
     *     }, this.onModalShow.bind(this));
     * }
     */
    class GlobalModal extends Modal {
        wrapperClasses?: string | Array<string>;

        onAfterCloseListener?: (globalModal: this) => void;

        onCancelListener?: (globalModal: this) => void;

        /**
         * @description Widget initialization logic
         * @listens module:events#dialogshow
         */
        init() {
            super.init();
            this.eventBus().on('dialogshow', 'showModal');
            this.eventBus().on('dialogclose', 'closeModal');
        }

        /**
         * @description Shows modal. Depending on needs, content for modal could be loaded via URL, or fetched from parameters.
         * Input parameter also contains setup for modal, like container classes, action buttons, heading text etc.
         * @example <caption>An object with expected parameters</caption>
         * {
         *      showActions: true,
         *      headerText: 'Header text',
         *      actions: [{
         *          text: 'Cancel',
         *          handler: 'cancel',
         *          classes: 'm-outline'
         *      },{
         *          text: 'Confirm',
         *          handler: 'cancel',
         *          lastFocusElement: true
         *      }],
         *      wrapperClasses: 'class1 class2',
         *      ariaLabel: 'test aria label',
         *      url: 'http://www.google.com',
         *      contentType: 'json'
         * }
         * In case if input parameter is an instance of {@link EmitBusEvent},
         * modal setup properties will be taken from `data-modal-config`
         * attribute of a {@link EmitBusEvent} widget.
         * @emits GlobalModal#show
         * @param templateData Input object for modal popup. See example above.
         * @param cb - Callback function
         * @returns Promise object represents modal rendering result
         */
        showModal(templateData?: Record<string, unknown> | InstanceType<TEmitBusEvent>, cb?: () => void): Promise<void> {
            let modalData: {
                content?: string;
                url?: string;
                contentType?: string;
                wrapperClasses?: Array<string> | string;
                bodyClass?: string;
                trigger?: Node;
                onAfterClose?: (globalModal: GlobalModal) => void;
                onCancel?: (globalModal: GlobalModal) => void;
            };

            const EmitBusEventClass = <TEmitBusEvent> this.getConstructor('emitBusEvent');

            if (templateData instanceof EmitBusEventClass) {
                modalData = {
                    url: templateData.data<string>('url')
                };
                const modalConfig = templateData.data<Record<string, unknown>>('modalConfig');

                if (modalConfig && Object.keys(modalConfig).length) {
                    modalData = Object.assign(modalData, modalConfig);
                }
            } else {
                modalData = templateData || {};
            }

            this.onCancelListener = modalData.onCancel;
            this.onAfterCloseListener = modalData.onAfterClose;

            this.wrapperClasses = modalData.wrapperClasses;

            if (modalData.bodyClass) {
                this.classesGlobalDialog = modalData.bodyClass;
            }

            this.beforeOpenModal(modalData);

            if (modalData.content) {
                return super.showModal(modalData, cb).then(() => {
                    if (this.wrapperClasses) {
                        this.ref(this.prefs().refContainer).addClass(this.wrapperClasses);
                    }
                }).finally(() => {
                    /**
                     * @description Event dispatched, when Global Modal was shown
                     * @event GlobalModal#show
                     */
                    this.emit('show');
                });
            }

            if (!modalData.url) {
                return Promise.resolve();
            }

            super.showModalPreview(modalData.wrapperClasses);

            if (modalData.contentType === 'json') {
                return getJSONByUrl(modalData.url, undefined, true).then(res => {
                    return this.showModalOnResponse(res, modalData, cb);
                });
            } else {
                return getContentByUrl(modalData.url).then(res => {
                    return this.showModalOnResponse(res, modalData, cb);
                });
            }
        }

        /**
         * @description process response data and pass it to modal
         * @param response - response object
         * @param modalData - modal data
         * @param cb - callback function
         * @returns Promise object represents modal rendering result
         */
        showModalOnResponse(response, modalData, cb) {
            if (Object.keys(response).length) {
                modalData = Object.assign(modalData, { content: response });
                const promise = super.showModal(modalData, cb, Boolean(modalData.url));

                /**
                 * @description Event dispatched, when Global Modal was shown
                 * @event GlobalModal#show
                 */
                this.emit('show');

                return promise;
            }

            return Promise.resolve();
        }

        /**
         * @description Removes wrap classes from modal
         */
        removeWrapperClasses() {
            if (this.wrapperClasses) {
                const container = this.ref(this.prefs().refContainer);

                container.removeClass(this.wrapperClasses);

                this.wrapperClasses = undefined;
            }
        }

        /**
         * @description Before open modal callback
         * @param modalData - modal data
         */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeOpenModal(modalData: object) {
            // just for extending
        }

        /**
         * @description On after modal close callback
         */
        onAfterCloseModal() {
            this.removeWrapperClasses();

            if (this.onAfterCloseListener) {
                this.onAfterCloseListener(this);
                this.onAfterCloseListener = undefined;
            }
        }

        /**
         * @description Cancel Handler
         */
        cancel() {
            this.close();
            super.cancel();

            if (this.onCancelListener) {
                this.onCancelListener(this);
                this.onCancelListener = undefined;
            }
        }
    }

    return GlobalModal;
}

export type TGlobalModal = ReturnType<typeof GlobalModalClassCreator>;

export type TGlobalModalInstance = InstanceType<TGlobalModal>;

export default GlobalModalClassCreator;

/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/reviews/ReviewsList').then(d => d.default),
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/reviews/ReviewItem').then(d => d.default),
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/reviews/SearchReviewsForm').then(d => d.default),
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/reviews/ReviewsMgr').then(d => d.default),
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/forms/ReviewForm').then(d => d.default),
        import(/* webpackChunkName: 'turnto.widgets' */ './widgets/reviews/ImageLoader').then(d => d.default)
    ]).then(deps => {
        const [
            ReviewsList,
            ReviewItem,
            SearchReviewsForm,
            ReviewsMgr,
            ReviewForm,
            ImageLoader
        ] = deps;

        return {
            listId: 'turnto.widgets',
            widgetsDefinition: () => [
                ['reviewsList', ReviewsList],
                ['reviewItem', ReviewItem],
                ['searchReviewsForm', SearchReviewsForm],
                ['reviewsMgr', ReviewsMgr],
                ['reviewForm', ReviewForm, 'ajaxform'],
                ['imageLoader', ImageLoader]
            ]
        };
    })
});

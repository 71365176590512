/**
 * @description Base InputTel implementation
 * @param InputText Base widget for extending
 * @returns Input Tel class
 */
function InputTelClassCreator(InputText: ReturnType<typeof import('./InputText').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputTel
     * @augments InputText
     * @classdesc Input type Tel implementation. Represents input `tel` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     *
     * @example <caption>InputTel definition in dynamicForms.js</caption>
     * ...
     * // fields -> input -> tel
     * text: {
     *     extends: 'fields.input.base',
     *     validation: {
     *         'patterns.parse': 'validation.patterns.parse.phone',
     *         'errors.parse': 'validation.errors.phone'
     *     },
     *     'label.text': 'form.address.phone',
     *     'caption.show': true,
     *     prefBased: {
     *         sitePrefName: 'phoneFieldValidationOptions',
     *         configs: {
     *             countryCodeSelector: {
     *                 'caption.show': false,
     *                 validation: {
     *                     'patterns.parse': 'validation.patterns.parse.phone.allowedcharacters',
     *                     'errors.minLength': 'validation.errors.digits.minlength',
     *                     'errors.maxLength': 'validation.errors.digits.maxlength'
     *                 }
     *             }
     *         }
     *     },
     *     element: {
     *         type: 'tel',
     *         minLength: 7,
     *         maxLength: 20,
     *         attributes: {
     *             autocomplete: 'tel',
     *             'data-event-input': 'onInput'
     *         }
     *     },
     *     widget: {
     *         attributes: {
     *             'data-mask': '^\\+?\\d*$',
     *             'data-mask-type': 'RegExp'
     *         }
     *     },
     * }
     * @example <caption>Insertion of InputTel inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     <isprint value="${
     *         formElement(pdict.addressForm.phone, pdict.addressFormOptions)
     *         .setData('element.attributes.data-tau', 'address_phone')
     *         .render()
     *     }" encoding="off"/>
     * </form>
     * @example <caption>Resulted HTML structure for InputTel</caption>
     * <div
     *     data-widget="inputTel"
     *     data-mask="^\+?\d*$"
     *     data-mask-type="RegExp"
     *     data-default-country="us"
     *     class="b-form_section m-required"
     *     data-id="dwfrm_address_phone"
     *     data-validation-config="..."
     * >
     *     <label class="b-form_section-label" for="dwfrm_address_phone">
     *         <span class="b-form_section-required" aria-hidden="true">*</span>
     *         Phone Number
     *     </label>
     *     <div class="iti__flag-container">
     *     ... flag icon content
     *     </div>
     *     <input data-ref="field"
     *         id="dwfrm_address_phone"
     *         type="tel"
     *         class="b-input"
     *         placeholder="(201) 555-0123"
     *         aria-describedby="dwfrm_address_phone-error"
     *         name="dwfrm_address_phone"
     *         value=""
     *         required=""
     *         maxlength="20"
     *         minlength="7"
     *         autocomplete="tel"
     *         data-event-input="onInput"
     *         data-tau="address_phone"
     *         data-event-blur="validate"
     *         data-intl-tel-input-id="0"
     *     >
     * </div>
    */
    class InputTel extends InputText {}

    return InputTel;
}

export type TInputTel = ReturnType<typeof InputTelClassCreator>;

export type TInputTelInstance = InstanceType<TInputTel>;

export default InputTelClassCreator;

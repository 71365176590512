/**
 * @module widgetsMgr
 * @category widgets
 * @subcategory framework
 */

/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
if ('assetsStaticURL' in window) {
    // eslint-disable-next-line no-undef, camelcase
    __webpack_public_path__ = window.assetsStaticURL;
}

import './_polyfills';
import { log, getData } from './toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';
import Widget from 'widgets/Widget';
import {
    init as initViewType,
    getActiveViewtypeName,
    ALL_VIEW_TYPES,
    TShortViewtypes
} from 'widgets/toolbox/viewtype';
import eventBus from 'widgets/toolbox/eventBus';

export const WIDGET_PROP_NAME = '@@_widget_instance_@@';
const WIDGET_DISPOSABLE_VALUES = '@@_widget_events_disposable_@@';
const WIDGET_DOM_EVENT_PREFIX = 'data-event-';
const DATA_ATTR_PREFIX = 'data-';
const DATA_WIDGET = 'data-widget';
const DATA_WIDGET_VIEWTYPE_RELATED = 'data-widget.';
const WIDGET_EVENT_PREFIX = 'data-widget-event-';
const DATA_INITIALIZED = 'data-initialized';
const DATA_REF = 'data-ref';
const DATA_CONTEXT = 'data-context';

import type * as diffDOM from 'diff-dom/src/index';

/**
 * @description check if there are viewtype modifiers
 * @param modifiers to check
 * @returns true if any viewtype modifier exist
 */
const isViewtypeModifiers = (modifiers: Array<TShortViewtypes>): boolean => modifiers.some(m => ALL_VIEW_TYPES.includes(m));
/**
 * @description check if HTML Element is widget
 * @param el element to check
 * @returns true if widget
 */
const isWidget = (el: HTMLElement): boolean => el.hasAttribute(DATA_WIDGET)
    || el.getAttributeNames().some(name => name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED));
/**
 * @description get widget name for current viewtype
 * @param el widget element
 * @returns widget name if exist
 */
const getViewtypeRelatedWidgetName = (el: HTMLElement): string | null => {
    const activeViewtypeName = getActiveViewtypeName();
    const viewtypeWidgetConfigs = el.getAttributeNames().filter(name => name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED));

    if (viewtypeWidgetConfigs.length) {
        const attrName = viewtypeWidgetConfigs.find(name => name.includes(activeViewtypeName));

        return attrName ? el.getAttribute(attrName) : 'widget';
    } else {
        return null;
    }
};
/**
 * @description check if element has viewtype-related widget definition or widget properties
 * @param el element
 * @returns widget name if exist
 */
const isViewtypeRelatedWidget = (el: HTMLElement): boolean => {
    if (el.getAttribute(DATA_WIDGET)) {
        return el.getAttributeNames().some(name => name.startsWith(DATA_ATTR_PREFIX) // is data attr
            && !name.startsWith(WIDGET_EVENT_PREFIX) // not widget event
            && !name.startsWith(WIDGET_DOM_EVENT_PREFIX) // not dom event
            && ALL_VIEW_TYPES.some(m => name.includes(m))); // is viewtype related property
    } else {
        return isWidget(el); // is viewtype related widget definition
    }
};

class RootWidget extends Widget {}

if (!document.head.parentElement) {
    throw Error('No document');
}

type TDomWidgetConfig = {
    [key: string]: Record<string, unknown> | string | number | boolean | null | undefined;
}

/**
 * @description get initial widget state from data attributes
 * @param domNode element of widget
 * @returns json-like configuration
 */
function getWidgetConfig(domNode: HTMLElement): TDomWidgetConfig {
    const config: TDomWidgetConfig = {};
    const activeViewtype = getActiveViewtypeName();

    domNode.getAttributeNames().forEach(attrName => {
        if (typeof attrName === 'string'
            && attrName.includes(DATA_ATTR_PREFIX)
            && !attrName.startsWith(WIDGET_DOM_EVENT_PREFIX)
            && !attrName.startsWith(DATA_WIDGET)
        ) {
            const [key, ...modifiers] = attrName.replace(DATA_ATTR_PREFIX, '').split('.');
            const camelCaseKey = camelCase(key);
            const isActiveViewtypeModifier = modifiers.includes(activeViewtype);
            const isViewtypeModifier = isViewtypeModifiers(<Array<TShortViewtypes>>modifiers);
            const isConfigNotExist = typeof config[camelCaseKey] === 'undefined';

            // If modifiers has activeViewtype that we definitely need set it to config, even if we already have value in config (most probable there can be only default value set)
            // If not check if it is default value (not have modifiers) and if value was not previosly set by attribute with modifiers then we will set default value to config
            if (isActiveViewtypeModifier || (!isViewtypeModifier && isConfigNotExist)) {
                config[camelCaseKey] = getData(domNode.getAttribute(attrName));
            }
        }
    });

    const jsonConfig = domNode.getAttribute('data-json-config');

    if (jsonConfig) {
        try {
            const parsedConfig = JSON.parse(jsonConfig);

            return { ...config, ...parsedConfig };
        } catch (error) {
            if (PRODUCTION) {
                log.error(`Invalid json config for widget ${domNode} ${error}`);
            } else {
                throw new Error(`Invalid json config for widget ${domNode} ${error}`);
            }
        }
    }

    return config;
}

/**
  * @description detach/attach widgets to elements by name
  * @param el root element for re-initialization widgets
  * @param widgetsListNames - widgets list
  */
function reinitElementByWidgetsNamesList(
    el: HTMLElement | null = document.head.parentElement,
    widgetsListNames: Array<string> = []
) {
    if (!el) {
        return;
    }

    const widgetName = el && el.getAttribute(DATA_WIDGET);

    if ((widgetName && widgetsListNames.includes(widgetName)) || isViewtypeRelatedWidget(el)) {
        detachElement(el);
        attachElements(el);

        return;
    }

    if (!el.children) {
        return;
    }

    // recursion
    // disposable not view type events
    // assign view type events
    const attrs = el.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_EVENT_PREFIX));
    const reloadEvents = attrs.some(attr => {
        const [, ...modifiers] = <[x: unknown, ...m: Array<TShortViewtypes>]>attr.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.');

        return modifiers.some(mod => ALL_VIEW_TYPES.includes(mod));
    });

    if (reloadEvents) {
        const parentWidget = el[WIDGET_PROP_NAME] || findParentWidget(el);
        const disposableValues = el[WIDGET_DISPOSABLE_VALUES];

        if (disposableValues) {
            el[WIDGET_DISPOSABLE_VALUES] = disposableValues.filter((dispose) => {
                if (dispose.eventName) {
                    // Dispose only events
                    dispose();

                    return false;
                }

                return true;
            });
        }

        attrs.forEach(attachEventToElement(el, parentWidget));
    }

    Array.from(el.children).forEach(child => {
        reinitElementByWidgetsNamesList(<HTMLElement> child, widgetsListNames);
    });
}

const rootWidget = new RootWidget(document.head.parentElement, {});
const widgetsInitMetric: {[widgetName: string]: number} = Object.create(null);
let initialized = false;

/**
 * @description Widget Mixin Function
 */
type TMixinFunction<T = typeof Widget> = (base: never) => T;

/**
 * @description Widget Definition Config contain Mixin function and parent widget name (if exist)
 */
type TWidgetDefinitionConfig = [TMixinFunction, string|undefined];

/**
 * @description Simple Widget Registry record
 */
type TSimpleWidgetRegistry = [string, TMixinFunction];

/**
 * @description Simple Widget Registry record
 */
type TExtendedWidgetRegistry = [string, TMixinFunction, string];

/**
 * @description Widget Registry record
 */
type TWidgetRegistryRecord = TSimpleWidgetRegistry | TExtendedWidgetRegistry;

/**
 * @description Async widget list
 */
type TAsyncList = {
    getLoadingFlag: () => boolean;
    load: () => Promise<{ listId: string; widgetsDefinition: () => Array<TWidgetRegistryRecord> }>;
    loaded?: boolean;
};

class WidgetMgr {
    /**
     * @description Map of widgets configurations in format: `widgetId: [widgetFunction, dependencyWidgetId]
     */
    widgets: {[key: string]: TWidgetDefinitionConfig | undefined} = Object.create(null);

    /**
     * @description Map of widgets list generators in format `listId: generatorFunction`
     * Generator function return widgets list
     */
    widgetsLists: {[key: string]: () => Array<TWidgetRegistryRecord>} = Object.create(null);

    /**
     * @description Array of registered widgets lists IDs
     */
    widgetsListsNameSpaceOrder: Array<string> = [];

    /**
     * @description Map of cached widgets classes in format `widgetId: returnsClassFunction`
     */
    widgetsClassCache: {[key: string]: typeof Widget|undefined} = Object.create(null);

    /**
     * @description Async list contain widget definition that loaded asynchronously
     */
    asyncLists: Array<TAsyncList> = [];

    hashRegistry: { [key: string]: Array<string> | undefined } = Object.create(null);

    /**
     * @description Timestamp for widgets evaluate
     */
    timeOfEvaluate: number;

    /**
     * @description Timestamp for widgets run
     */
    timeOfRun: number;

    /**
     * @description Timestamp for widgets init
     */
    timeOfInit: number;

    /**
     * @description Flag that indicate if dom loaded before widget initialization
     */
    hasDomLoadedFirst = false;

    /**
     * Record of callbacks that will be triggered after widget initialization is finished
     * Object used instead of array to make sure that the same callbacks will not be added multiple times accidentally
     */
    onInitialized: Record<string, () => void> = {};

    constructor() {
        this.timeOfEvaluate = Date.now();
        this.timeOfRun = Date.now();
        this.timeOfInit = Date.now();
        this.widgets.widget = [() => Widget, ''];

        initViewType();
        eventBus.on('context.add', (contextId) => {
            if (!window.contexts.includes(contextId)) {
                window.contexts.push(contextId);
            }

            this.run(contextId);
        });
    }

    /**
     * @description When DOM is ready, the method starts registering widgets, for each assigned widgets list, and executes WidgetMgr.init()
     * @param [contextId] Id of context to be added
     */
    run(contextId?: string) {
        const asyncListPromises = this.asyncLists.filter(list => !list.loaded).filter(list => list.getLoadingFlag()).map(list => {
            list.loaded = true;

            return list.load();
        });

        Promise.all(asyncListPromises).then((asyncLists) => {
            asyncLists.forEach(({ listId, widgetsDefinition }) => this.addWidgetsList(listId, widgetsDefinition));

            if (contextId) {
                asyncLists.forEach(asyncList => {
                    this.registerWidgetsList(asyncList.listId);
                });
                document.querySelectorAll(`[${DATA_CONTEXT}~="${contextId}"]`).forEach(el => {
                    const contexts = el.getAttribute(DATA_CONTEXT);

                    if (contexts === contextId) {
                        el.removeAttribute(DATA_CONTEXT);
                    } else if (contexts) {
                        el.setAttribute(
                            DATA_CONTEXT,
                            contexts.split(' ')
                                .filter(context => context !== contextId)
                                .join(' ')
                        );
                    }

                    if (el instanceof HTMLElement) {
                        attachElements(el);
                    }
                });

                return;
            }

            this.widgetsListsNameSpaceOrder.forEach(this.registerWidgetsList, this);
            this.timeOfRun = Date.now();

            // Init widgets once DOM is ready
            if (document.readyState === 'loading') {
                this.hasDomLoadedFirst = false;
                document.addEventListener('DOMContentLoaded', () => {
                    setTimeout(() => this.init(), 0);
                }, { once: true });
            } else {
                this.hasDomLoadedFirst = true;
                this.init();
            }
        });
    }

    /**
     * @description Returns all registered widgets.
     * @returns all widgets
     */
    getAll(): WidgetMgr['widgets'] {
        return this.widgets;
    }

    /**
     * @description Returns registered widget by name.
     * @param [name] Name of registered widget (used as data-widget="<name>" in DOM)
     * @returns return widget class by name
     */
    get(name?: string): typeof Widget {
        if (name) {
            const cachedClass = this.widgetsClassCache[name];

            if (cachedClass) {
                return cachedClass;
            }

            const widgetsConfig = this.widgets[name];

            if (widgetsConfig) {
                const [getWidgetClass, baseWidget] = widgetsConfig;
                const widgetClass = getWidgetClass(<never> this.get(baseWidget));

                this.widgetsClassCache[name] = widgetClass;

                return widgetClass;
            }

            // eslint-disable-next-line no-console
            console.error(`widgetClass with ID "${name}" missed in widgets config`);
        }

        return Widget;
    }

    /**
     * @description add widget by name into registry
     * @param name of widget in registry
     * @param widget definition configuration to set
     * @returns widget return set widget config
     */
    set(name: string, widget: TWidgetDefinitionConfig): TWidgetDefinitionConfig {
        this.widgets[name] = widget;

        return widget;
    }

    /**
     * @description Add widgets list into registry
     * @param nameSpace - name of widgets list
     * @param cb - function that returns widget list
     */
    addWidgetsList(nameSpace: string, cb: () => Array<TWidgetRegistryRecord>) {
        if (!this.widgetsListsNameSpaceOrder.includes(nameSpace)) {
            this.widgetsListsNameSpaceOrder.push(nameSpace);
        }

        if (!PRODUCTION && this.widgetsLists[nameSpace]) {
            // eslint-disable-next-line no-console
            console.error(`Widget List with listId "${nameSpace}" already exist. listId should be unique!`);
        }

        this.widgetsLists[nameSpace] = cb;
    }

    /**
     * @description Register all widgets from widgets list by name space
     * @param nameSpace - widgets list name space
     */
    registerWidgetsList(nameSpace: string) {
        if (!PRODUCTION) {
            // eslint-disable-next-line no-console
            console.groupCollapsed(`${nameSpace} widgets registration`);
        }

        this.widgetsLists[nameSpace]().forEach(args => this.register(args[0], args[1], args[2]));

        if (!PRODUCTION) {
            // eslint-disable-next-line no-console
            console.groupEnd();
        }
    }

    /**
     * @description set hash to registry by widget name with widget dependency
     * @param hashRegistry registry object
     * @param name widget in hashRegistry
     * @param baseWidget name of base widget
     */
    setHash(hashRegistry: WidgetMgr['hashRegistry'], name: string, baseWidget = '') {
        if (!hashRegistry[name]) {
            hashRegistry[name] = [name];
        }

        const currentHash = hashRegistry[name];

        if (baseWidget && currentHash) {
            currentHash.push(baseWidget);
        }
    }

    /**
     * @description get widget hash from registry by name
     * @param hashRegistry registry object
     * @param name widget in hashRegistry
     * @returns hash for widget
     */
    getHash(hashRegistry: WidgetMgr['hashRegistry'], name: string): string {
        return (hashRegistry[name] || []).reduce((currentWidget, baseWidget) => {
            let hash;

            if (name === baseWidget) {
                hash = baseWidget;
            } else {
                hash = this.getHash(hashRegistry, baseWidget);
            }

            return currentWidget + hash;
        }, '');
    }

    /**
     * @description add widget into registry
     * @param name name of widget (will be used in `data-widget="name"`)
     * @param widgetConstructor function mixin that returns class
     * @param [baseWidget] base widget to extend
     */
    register(name: string, widgetConstructor: TMixinFunction, baseWidget?: string) {
        const widgetConfig = this.widgets[name];

        if (widgetConfig && baseWidget === name) {
            const [superWidgetConstructor, superBaseWidget] = widgetConfig;

            this.set(name, [base => widgetConstructor(<never> superWidgetConstructor(base)), superBaseWidget]);
        } else {
            this.set(name, [widgetConstructor, baseWidget]);
        }

        this.setHash(this.hashRegistry, name, baseWidget);
    }

    /**
     * @description Returns list of widgets changed during 'viewtype.change' event
     * @returns widgets that was changed due to viewport change
     */
    getChangedWidgets(): Array<TWidgetRegistryRecord> {
        const emptyArray: Array<TWidgetRegistryRecord> = [];
        const newRegistry = this.widgetsListsNameSpaceOrder
            .map(ns => this.widgetsLists[ns]())
            .reduce((prev, nsList) => [...prev, ...nsList], emptyArray);

        // find extendable widgets
        const newHash: WidgetMgr['hashRegistry'] = Object.create(null);

        newRegistry.forEach(([widgetName, , baseName]) => this.setHash(newHash, widgetName, baseName));

        const changedWidgets = newRegistry
            .filter(([widgetName]) => this.getHash(newHash, widgetName) !== this.getHash(this.hashRegistry, widgetName));

        return changedWidgets;
    }

    /**
     * @description Re-init widgets changed during 'viewtype.change' event and re-assign viewtype related events
     */
    updateMutableComponents() {
        if (!initialized) {
            this.onInitialized.updateMutableComponents = this.updateMutableComponents.bind(this);

            return;
        }

        const changedWidgets = this.getChangedWidgets();

        if (!changedWidgets.length) {
            reinitElementByWidgetsNamesList();

            return;
        }

        const widgetsNames = changedWidgets.map(w => w[0]);

        // remove definitions
        changedWidgets.forEach(([name]) => {
            this.hashRegistry[name] = undefined;
            this.widgets[name] = undefined;
            this.widgetsClassCache[name] = undefined;
        });
        // register again
        changedWidgets.forEach(args => this.register(args[0], args[1], args[2]));
        reinitElementByWidgetsNamesList(document.head.parentElement, widgetsNames);
    }

    /**
     * @description Destroy all widgets/events and construct them again. Needed for webpack HMR during change code for any widget
     * @param el - root element from which start restart of widgets
     */
    restartWidgets(el: HTMLElement | null = document.head.parentElement) {
        if (el) {
            detachElement(el);
            this.hashRegistry = {};
            this.widgets = {};
            this.widgets.widget = [() => Widget, ''];
            this.widgetsClassCache = {};
            this.widgetsListsNameSpaceOrder.forEach(this.registerWidgetsList, this);
            attachElements(el);
        }
    }

    /**
     * @description destroy widget or refElement, run disposable for events for element
     * @param el element for processing attributes
     * @param diff diffDom internal object of changes
     */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    removeAttribute(el: HTMLElement, diff: diffDOM.IDiff) {
        if (diff.name === DATA_REF) {
            el[WIDGET_DISPOSABLE_VALUES] = (el[WIDGET_DISPOSABLE_VALUES] || []).filter(disposable => {
                if (disposable.attrName === 'ref') {
                    disposable();

                    return false;
                }

                return true;
            });
        } else if (DATA_WIDGET === diff.name || diff.name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED)) {
            detachElement(el);
        } else if (diff.name.startsWith(WIDGET_DOM_EVENT_PREFIX)) {
            const name = diff.name.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.')[0];
            const value = diff.value || diff.oldValue;

            el[WIDGET_DISPOSABLE_VALUES] = (el[WIDGET_DISPOSABLE_VALUES] || []).filter(disposable => {
                if (disposable.eventName === name && disposable.methodToCall === value) {
                    disposable();

                    return false;
                }

                return true;
            });
        } else if (el[WIDGET_PROP_NAME]) {
            handleWidgetPropertyChange(el, diff);

            if (diff.name.startsWith(WIDGET_EVENT_PREFIX)) {
                addRelationships(el[WIDGET_PROP_NAME], el);
            }
        }
    }

    /**
     * @description Create widget or refElement, assign events for element
     * @param el element for processing attributes
     * @param diff diffDom internal object of changes
     */
    addAttribute(el: HTMLElement, diff: diffDOM.IDiff) {
        let widget = el[WIDGET_PROP_NAME];

        if (diff.name === DATA_REF) {
            widget = el[WIDGET_PROP_NAME] || findParentWidget(el);

            if (widget && widget.refs) {
                const refEl = new RefElement([el]);

                widget.refs[diff.value || diff.newValue] = refEl;
                disposableForParent(el, widget, refEl, diff.value || diff.newValue);
            }
        } else if (DATA_WIDGET === diff.name || diff.name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED)) {
            attachElements(el);
        } else if (diff.name.startsWith(WIDGET_DOM_EVENT_PREFIX)) {
            attachEventToElement(el, el[WIDGET_PROP_NAME] || findParentWidget(el))(diff.name);
        } else if (el[WIDGET_PROP_NAME] && widget && diff.name !== DATA_INITIALIZED) {
            widget.config = getWidgetConfig(el);
        } else if (diff.name.startsWith(WIDGET_EVENT_PREFIX) && widget) {
            addRelationships(widget, el);
        }
    }

    /**
     * @description Attaching widget to DOM elements from parent to child recursively, by Widgets.attachElements(element)
     * Initialize mutation observer
     */
    init() {
        if (!PRODUCTION) {
            this.timeOfInit = Date.now();
            // log.profile('Initialization widgets');
        }

        if (!document.head.parentElement) {
            throw Error('No document');
        }

        const observer = new MutationObserver(mutations => mutations.forEach((mutation) => {
            this.handleMutations(mutation);
        }));

        observer.observe(document.body, {
            attributes: false,
            characterData: false,
            childList: true,
            subtree: true
        });

        attachElements(document.head.parentElement);

        eventBus.on('viewtype.change', () => this.updateMutableComponents());

        if (!PRODUCTION) {
            const total = Date.now() - window.headInitTime;
            const timeInitWidgets = Date.now() - this.timeOfInit;
            const timeToRegisterWidgets = this.timeOfRun - this.timeOfEvaluate;
            const loadAndScriptingTime = this.timeOfEvaluate - window.headInitTime;

            let waitToDomLoaded = 0;

            waitToDomLoaded = this.hasDomLoadedFirst ? (window.domReadyTime - this.timeOfInit) : this.timeOfInit - this.timeOfRun;

            const headToDomReady = (window.domReadyTime || this.timeOfRun) - window.headInitTime;

            log.table({
                headToDomReady: {
                    ms: headToDomReady,
                    percentage: 0
                },
                loadAndScriptingTime: {
                    ms: loadAndScriptingTime,
                    percentage: Math.round((loadAndScriptingTime / total) * 100)
                },
                registerWidgetsTime: {
                    ms: timeToRegisterWidgets,
                    percentage: Math.round((timeToRegisterWidgets / total) * 100)
                },
                waitToDomLoaded: {
                    ms: waitToDomLoaded,
                    percentage: waitToDomLoaded > 0 ? Math.round((waitToDomLoaded / total) * 100) : 0
                },
                initWidgetsTime: {
                    ms: timeInitWidgets,
                    percentage: Math.round((timeInitWidgets / total) * 100)
                },
                total: {
                    ms: total,
                    percentage: Math.round(100)
                }
            });

            if (timeToRegisterWidgets > 50) {
                log.warn('High time of widgets registration');
            }

            if (timeInitWidgets > 50) {
                log.warn('High time of widgets initialization');
            }

            widgetsInitMetric.total = Object.values(widgetsInitMetric).reduce((a, b) => a + b, 0);
            log.groupCollapsed('Widgets initialization time (init method)');
            log.table(widgetsInitMetric);
            log.groupEnd();
        }

        // CLARIFY: What is magic timeout for 500 ms?
        setTimeout(() => {
            initialized = true;
            Object.values(this.onInitialized).forEach(callback => callback());
        }, 500);
    }

    /**
     * @description mutations handler for MutationObserver
     * @param mutation record of MutationObserver
     */
    handleMutations(mutation: MutationRecord) {
        const { addedNodes, removedNodes } = mutation;

        removedNodes.forEach(removedNode => {
            if (removedNode.nodeType === removedNode.ELEMENT_NODE) {
                detachElement(<HTMLElement>removedNode);
            }
        });
        addedNodes.forEach(addedNode => {
            if (addedNode.nodeType === addedNode.ELEMENT_NODE && document.body.contains(addedNode)) {
                attachElements(<HTMLElement>addedNode);
            }
        });
    }
}

const widgetsMgr = new WidgetMgr();

// Matches dashed string for camel case
const rmsPrefix = /^-ms-/;
const rdashAlpha = /-([a-z])/g;

/**
 * @description handler for Widget.onRefresh() lifecycle hook
 * @param el element for processing attributes
 * @param diff diffDom internal object of changes
 */
function handleWidgetPropertyChange(el: HTMLElement, diff: diffDOM.IDiff) {
    const widget = el[WIDGET_PROP_NAME];

    if (widget) {
        if (diff.name === DATA_INITIALIZED) {
            if (!widget.isRefreshingWidget) {
                widget.isRefreshingWidget = true;
                widget.onRefresh();
                widget.isRefreshingWidget = false;
            }

            el.setAttribute(DATA_INITIALIZED, '1');
        } else {
            widget.config = getWidgetConfig(el);
        }
    }
}

/**
 * @description Convert dashed to camelCase; used by the css and data modules
 * @param string String to convert
 * @returns Converted string
 */
function camelCase(string: string): string {
    return string.replace(rmsPrefix, 'ms-').replace(rdashAlpha, (_all, letter) => letter.toUpperCase());
}

/**
 * @description Get widget name from node or instance
 * @param widgetInstance Widget instance
 * @returns Message with widget name
 */
function getWidgetName(widgetInstance: Widget | null): string {
    if (!widgetInstance) {
        return 'name not found';
    }

    const widgetNode = widgetInstance.ref('self').get();

    if (widgetNode && widgetNode.getAttribute(DATA_WIDGET)) {
        return widgetNode.getAttribute(DATA_WIDGET) as string;
    }

    if (widgetInstance.constructor.name) {
        return widgetInstance.constructor.name;
    }

    return 'name not found';
}

/**
 * @description Get attributes data from widget dom element
 * @param widgetNode widget element
 */
function getWidgetDataMsg(widgetNode: HTMLElement | undefined): string {
    if (!widgetNode) {
        return '';
    }

    const widgetData: Array<string> = [''];

    if (widgetNode.getAttribute('data-id')) {
        widgetData.push(`data-id="${widgetNode.getAttribute('data-id')}"`);
    }

    if (widgetNode.getAttribute('id')) {
        widgetData.push(`id="${widgetNode.getAttribute('id')}"`);
    }

    return widgetData.join('\n');
}

/**
 * @description Create callback that will attach DOM events to element through `attachEventToWidget` using data-event- attributes
 * @param el DOM element
 * @param widgetInstance Widget instance
 * @returns callback
 */
function attachEventToElement(el: HTMLElement, widgetInstance: Widget|null): (attr: string) => void {
    return attr => {
        const [attrName, ...modifiers] = attr.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.');
        const attrValue = el.getAttribute(attr) || '@';

        if (!PRODUCTION && modifiers.length && ALL_VIEW_TYPES.every(m => modifiers.includes(m))) {
            log.error(
                `you shouldn't use ${WIDGET_DOM_EVENT_PREFIX}eventName.${ALL_VIEW_TYPES.join('.')}`
                + 'use it without modifiers'
            );
        }

        if (isViewtypeModifiers(<Array<TShortViewtypes>>modifiers) && !modifiers.includes(getActiveViewtypeName())) {
            return;
        }

        if (widgetInstance && typeof widgetInstance[attrValue] === 'function') {
            attachEventToWidget(modifiers, widgetInstance, attrName, attrValue, el);
        } else {
            log.error(
                [
                    `Widget "${getWidgetName(widgetInstance)}" don't have method "${attrValue}".`,
                    `Unable to assign event. Additional data: ${getWidgetDataMsg(widgetInstance?.ref('self').get())}`
                ].join(' '),
                { el }
            );
        }
    };
}

/**
 * @description Attach DOM events to widget instance specified in data-event- attributes
 * @param modifiers Event modifiers
 * @param widgetInstance Widget for event assignment
 * @param eventName DOM event
 * @param methodToCall widget method to call
 * @param el Widget self element to store disposables
 */
function attachEventToWidget(
    modifiers: Array<string>,
    widgetInstance: Widget,
    eventName: string,
    methodToCall: string,
    el: HTMLElement
) {
    let disposableValues = el[WIDGET_DISPOSABLE_VALUES] || [];

    const prevent = modifiers.includes('prevent');
    const stop = modifiers.includes('stop');
    const once = modifiers.includes('once');
    const self = modifiers.includes('self');

    disposableValues = disposableValues.filter((disposable) => {
        // If such event already exist need to remove it before attaching new event
        if (disposable.eventName === eventName && disposable.methodToCall === methodToCall && disposable.el === el) {
            disposable();

            return false;
        }

        return true;
    });

    let disposables = <Array<IDisposableFunction>> widgetInstance.ev(eventName, function eventHandler(this: Widget, element, event) {
        if (prevent) {
            event.preventDefault();
        }

        if (stop) {
            event.stopPropagation();
        }

        if (once && disposables) {
            disposables.forEach(disposable => disposable());
        }

        if (event.currentTarget !== event.target && self) {
            return;
        }

        const target = Object.values(widgetInstance.refs || {})
            .find((refEl) => Boolean(refEl && refEl instanceof RefElement && refEl.get() === element))
            || new RefElement([element]);

        widgetInstance[methodToCall].call(this, target, event);
    }, el, modifiers.includes('passive'));

    disposables = disposables.map((disposable) => {
        disposable.methodToCall = methodToCall;
        disposable.el = el;

        return disposable;
    });

    // register events to remove once removed from DOM
    disposableValues.push(...disposables);
    el[WIDGET_DISPOSABLE_VALUES] = disposableValues;
}

const noop = () => undefined;
const getConstructor: Widget['getConstructor'] = (name) => widgetsMgr.get(name);

/**
 * @description Create widget instance, assign it to parent, assign widget events
 * @param domNode element of widget
 * @returns Instance of initialized widget
 */
function initWidget(domNode: HTMLElement): InstanceType<typeof Widget> {
    let currentWidget: Widget;
    const registeredWidgets = widgetsMgr.getAll();
    const instance: Widget | undefined = domNode[WIDGET_PROP_NAME];
    let widgetName = domNode.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(domNode);

    if (!widgetName) {
        if (PRODUCTION) {
            // eslint-disable-next-line no-console
            console.error('Empty widget name on node:', domNode);
        } else {
            throw Error('Empty widget name');
        }

        widgetName = 'widget';
    }

    if (instance && instance.refs && instance.refs.self) {
        currentWidget = instance;
    } else {
        if (!registeredWidgets[widgetName]) {
            if (PRODUCTION) {
                // eslint-disable-next-line no-console
                console.error(`Widget "${widgetName}" is not found in registry on dom node: ${getWidgetDataMsg(domNode)}`);
                // Fallback to basic widget in case we found widget that not exist in registry
                widgetName = 'widget';
            } else {
                throw Error(`Widget "${widgetName}" is not found in registry: ${getWidgetDataMsg(domNode)}`);
            }
        }

        currentWidget = new (widgetsMgr.get(widgetName))(domNode, getWidgetConfig(domNode));
    }

    domNode[WIDGET_PROP_NAME] = currentWidget;

    if (!instance) {
        addRelationships(currentWidget, domNode);

        return currentWidget;
    }

    return instance;
}

/**
 * @description Add relationships between current widget and his parents
 * @param currentWidget current widget instance
 * @param domNode element of widget
 */
function addRelationships(currentWidget: Widget, domNode: HTMLElement) {
    const parentWidget = findParentWidget(domNode);

    if (parentWidget.items) {
        linkCurrentToParent(parentWidget, currentWidget);
    }

    if (currentWidget.refs && rootWidget.refs) {
        currentWidget.refs.html = rootWidget.refs.self;
    }

    currentWidget.parentHandler = noop;
    currentWidget.getConstructor = getConstructor;
    prepareWidgetAttributes(domNode, currentWidget, parentWidget);
    // currentWidget.parentHandler = parentWidget.eventHandler.bind(parentWidget);
}

/**
 * @description assign current widget into parent for parent->child structure
 * @param parentWidget parent widget instance
 * @param currentWidget current widget instance
 */
function linkCurrentToParent(parentWidget: Widget, currentWidget: Widget) {
    if (parentWidget && currentWidget) {
        if (parentWidget.items) {
            parentWidget.items.push(currentWidget);
            currentWidget.onDestroy(() => {
                if (parentWidget.items) {
                    const idx = parentWidget.items.indexOf(currentWidget);

                    if (idx > -1) {
                        parentWidget.items.splice(idx, 1);
                    }
                }
            });
        }
    }
}

/**
 * @description assign widget events to parent handlers
 * @param domNode HTML Element of widget
 * @param currentWidget current widget instance
 * @param  parentWidget parent widget instance
 */
function prepareWidgetAttributes(
    domNode: HTMLElement,
    currentWidget: InstanceType<typeof Widget>,
    parentWidget: InstanceType<typeof Widget>
) {
    const forwardToParent = domNode.getAttribute('data-forward-to-parent');

    if (forwardToParent) {
        forwardToParent.split(':').forEach(pair => {
            const [methodName, parentWidgetMethodName] = pair.split('-');

            currentWidget[methodName] = (...args: Array<unknown>) => {
                parentWidget[parentWidgetMethodName || methodName].call(parentWidget, ...args);
            };
        });
    }

    const attrs = domNode.getAttributeNames().filter(name => name.startsWith(WIDGET_EVENT_PREFIX));

    if (!attrs) {
        return;
    }

    attrs.forEach(attr => {
        const [attrName, ...modifiers] = attr.replace(WIDGET_EVENT_PREFIX, '').split('.');

        if (isViewtypeModifiers(<Array<TShortViewtypes>>modifiers) && !modifiers.includes(getActiveViewtypeName())) {
            return;
        }

        const attrValue = domNode.getAttribute(attr);
        const prevHandler = currentWidget.parentHandler;

        if (attrValue && typeof parentWidget[attrValue] === 'function') {
            currentWidget.parentHandler = (name, ...args) => {
                prevHandler(name, ...args);

                if (name === attrName && typeof parentWidget[attrValue] === 'function') {
                    parentWidget[attrValue].call(parentWidget, ...args);
                }
            };
        } else {
            log.error(`Widget "${parentWidget.constructor.name}" don't have method "${attrValue}"`);
        }
    });
}

/**
 * @description Recursive widgets creation and events assignment for HTML Element
 * @param element top element as entry point to start recursion
 */
function attachElements(element: HTMLElement) {
    let widgetInstance: Widget | undefined;

    if (isWidget(element) && !element[WIDGET_PROP_NAME]) {
        widgetInstance = initWidget(element);
    }

    const ref = element.getAttribute(DATA_REF);
    let parentWidget: Widget | null = null;

    if (ref) {
        parentWidget = element[WIDGET_PROP_NAME] || findParentWidget(element);

        if (parentWidget?.refs) {
            const refEl = new RefElement([element]);

            parentWidget.refs[ref] = refEl;
            // here we will listen for the event of removal parent widget
            disposableForParent(element, parentWidget, refEl, ref);
        }
    }

    const attrs = element.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_EVENT_PREFIX));

    if (attrs.length) {
        parentWidget = parentWidget || element[WIDGET_PROP_NAME] || findParentWidget(element);
        attrs.forEach(attachEventToElement(element, parentWidget));
    }

    if (!element.hasAttribute(DATA_CONTEXT)) {
        let child = element.firstElementChild;

        while (child) {
            attachElements(<HTMLElement>child);
            child = child.nextElementSibling;
        }
    }

    if (!widgetInstance) {
        return;
    }

    const startTime = Date.now();

    widgetInstance.init();
    element.setAttribute(DATA_INITIALIZED, '1');

    addWidgetMetricData(element, startTime);
}

/**
 * @description Add widget metric data for non-production instance
 * @param element Widget element
 * @param startTime Widget initialization start time
 */
function addWidgetMetricData(element: HTMLElement, startTime: number) {
    if (!PRODUCTION) {
        const widgetName = element.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(element);

        if (widgetName) {
            widgetsInitMetric[widgetName] = (widgetsInitMetric[widgetName] || 0) + (Date.now() - startTime);
        }
    }
}

/**
 * @description Listener for the event of removal parent widget
 * @param element Element to listen
 * @param parentWidget Parent widget
 * @param refEl linked ref element
 * @param ref RefElement id
 */
function disposableForParent(element: HTMLElement, parentWidget: Widget, refEl: RefElement, ref: string) {
    if (!element[WIDGET_DISPOSABLE_VALUES]) {
        element[WIDGET_DISPOSABLE_VALUES] = [];
    }

    const disposableValues = element[WIDGET_DISPOSABLE_VALUES];

    if (disposableValues) {
        const dispose: IDisposableFunction = () => {
            // remove reference if element is removed from DOM ref points to the same element
            if (parentWidget && parentWidget.refs && refEl === parentWidget.refs[ref]) {
                delete parentWidget.refs[ref];
            }
        };

        dispose.attrName = 'ref';
        disposableValues.push(dispose);
    }
}

/**
 * @description find parent widget by HTML Element
 * @param el entry point for searching
 * @returns parent widget
 */
function findParentWidget(el: HTMLElement): Widget {
    let parent: HTMLElement | null = el.parentElement;

    while (parent) {
        const widgetName = parent.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(parent);

        if (widgetName) {
            break;
        } else {
            parent = parent.parentElement;
        }
    }

    return (parent && parent[WIDGET_PROP_NAME]) || rootWidget;
}

/**
 * @description Destroy widgets, run disposables for element and elements inside
 * @param el top element for recursive procession
 */
function detachElement(el: HTMLElement) {
    const disposableValues = el[WIDGET_DISPOSABLE_VALUES];

    if (disposableValues) {
        disposableValues.forEach((dispose) => dispose());
        el[WIDGET_DISPOSABLE_VALUES] = undefined;
    }

    const currentWidget = el[WIDGET_PROP_NAME];

    if (currentWidget) {
        currentWidget.destroy();
        el[WIDGET_PROP_NAME] = undefined;

        if (!PRODUCTION && !initialized) {
            log.warn('Destroying widget before initialization is complete or right after init complete', el);
        }
    }

    let child = el.firstElementChild;

    while (child) {
        detachElement(<HTMLElement>child);
        child = child.nextElementSibling;
    }
}

/**
 * @description tool for visualization widgets and put widget object into console by click. just call it in your console and look
 */
window.initToolkit = (): Promise<void> => import(/* webpackChunkName: 'toolkit' */ 'widgets/widgetsToolkit')
    .then(widgetsToolkit => widgetsToolkit.initToolkit(widgetsMgr));

export default widgetsMgr;

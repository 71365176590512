import { RefElement } from 'widgets/toolbox/RefElement';
import { TWidget } from 'widgets/Widget';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @description Base Button implementation
 * @param Widget - widget for extending
 * @returns Button widget
 */
function ButtonClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Button
     * @augments Widget
     * @classdesc Button widget, which could be used in different contextes (html-markup, inside widgets etc).
     * <br>Could be also used to trigger parent's widget method
     * @property {string} data-widget - Widget name `button`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {boolean} data-prevent-action - Prevent action flag
     * @classdesc Represents Button component with the next features:
     * 1. Activate\deactivate button visibility on the storefront
     * 2. Enable\disable button visibility on the storefront
     * 3. Handles keydown by enter\space keyboard button
     * 4. Sets button error
     * 5. Marks button as pressed\unpressed
     *
     * @example <caption>Example of Button widget usage</caption>
     * <button
     *     type="submit"
     *     class="btn btn-block btn-primary"
     *     data-widget="button"
     *     data-widget-event-click="handleSubmit"
     *     data-event-click.prevent="handleClick"
     *     data-id="submitButton"
     * >
     *     ${Resource.msg('button.text.loginform', 'login', null)}
     * </button>
     */
    class Button extends Widget {
        disabled = false;

        isBusy = false;

        selected = false;

        /**
         * @description Returns Widget configuration object
         * @returns Widget configuration object
         */
        prefs() {
            return {
                classesError: 'm-error',
                classesActive: 'm-active',
                preventAction: false,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         */
        init() {
            super.init();

            if (!this.id) {
                this.id = String(this.config.id);
            }

            this.disabled = this.ref('self').attr('disabled') === 'disabled';
        }

        /**
         * @description Handles button click
         * @listens dom:click
         * @emits Button#click
         */
        handleClick() {
            if (!this.prefs().preventAction) {
                /**
                 * @description Emits button click event
                 * @event Button#click
                 */
                this.emit('click', this);
            }
        }

        /**
         * @description Returns button value
         */
        getValue(): string | RefElement {
            return this.ref('self').val();
        }

        /**
         * @param val - Value to set into widget's val attribute
         */
        setValue(val = ''): string | RefElement {
            this.setError();

            return this.ref('self').val(val);
        }

        /**
         * @description Returns button text
         */
        getText(): string {
            return this.ref('self').getText();
        }

        /**
         * @param val - Value to set as a text of the Button
         */
        setText(val = ''): RefElement {
            return this.ref('self').setText(val);
        }

        /**
         * @description Sets button text error
         * @param err - Sets `err` as a Button text and
         * if `err` is not empty - adds error class to Button
         */
        setError(err = '') {
            this.setText(err);

            if (err) {
                this.ref('self').addClass(this.prefs().classesError);
            }
        }

        /**
         * @description Method to mark element as `active`
         */
        activate() {
            this.ref('self').addClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @description Method to mark element as `inactive`
         */
        deactivate() {
            this.ref('self').removeClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @param state true to show/false to hide/undefined to auto
         */
        toggleActive(state?: boolean) {
            this.ref('self').toggleClass(this.prefs().classesActive, state);
        }

        /**
         * @description Check if a button has activated state
         */
        isActive(): boolean {
            return this.ref('self').hasClass(this.prefs().classesActive);
        }

        /**
         * @description Disables button
         */
        disable(): Button {
            this.disabled = true;
            this.ref('self').disable();

            return this;
        }

        /**
         * @description Enables button
         * @returns result
         */
        enable(): Button {
            this.disabled = false;
            this.ref('self').enable();

            return this;
        }

        /**
         * @description Marks button as busy
         */
        busy() {
            this.isBusy = true;
            this.ref('self').attr('aria-busy', 'true');
        }

        /**
         * @description Marks button as unbusy
         */
        unbusy() {
            this.isBusy = false;
            this.ref('self').attr('aria-busy', 'false');
        }

        /**
         * @description Marks button as selected
         */
        select() {
            this.selected = true;
            this.ref('self').attr('aria-selected', 'true');
        }

        /**
         * @description Marks button as unselected
         */
        unselect() {
            this.selected = false;
            this.ref('self').attr('aria-selected', 'false');
        }

        /**
         * @description Method to mark button as `pressed`.
         * Used only for button with 2 different states when pressed and not pressed. Ex:
         * Play / Paused; Favorite / Not favorite, etc.
         */
        press(): Button {
            this.ref('self').attr('aria-pressed', 'true');

            return this;
        }

        /**
         * @description Method to mark button as `unpressed`. Used only in buttons with 2 states.
         */
        unpress(): Button {
            this.ref('self').attr('aria-pressed', 'false');

            return this;
        }

        /**
         * @description Returns button name
         */
        getName(): string | RefElement {
            return this.ref('self').attr('name');
        }

        /**
         * @description Focus to element
         */
        focus(): Button {
            this.ref('self').focus();

            return this;
        }

        /**
         * @description Sets button to be accountable with `tab` button navigation
         */
        setTabIndex(): Button {
            this.ref('self').attr('tabindex', '0');

            return this;
        }

        /**
         * @description Sets button to be not accountable with `tab` button navigation
         */
        unsetTabIndex(): Button {
            this.ref('self').attr('tabindex', '-1');

            return this;
        }

        /**
         * @description Keydown Event handler
         * @param _ Source of keydown event
         * @param event  Event object
         * @listens dom:keydown
         */
        handleKeydown(_: HTMLElement, event: KeyboardEvent) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.handleClick();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Method to add class to widget container. Allows chaining.
         * @param className a class name to add to widget container
         * @returns result
         */
        addClass(className: string): Button {
            this.ref('self').addClass(className);

            return this;
        }

        /**
         * @description Method to remove class from widget container. Allows chaining.
         * @param className a class name to remove from widget container
         * @returns result
         */
        removeClass(className: string): Button {
            this.ref('self').removeClass(className);

            return this;
        }
    }

    return Button;
}

export type TButton = ReturnType<typeof ButtonClassCreator>;

export type TButtonInstance = InstanceType<TButton>;

export default ButtonClassCreator;

import { submitFormJson, errorFallbackMessage } from 'widgets/toolbox/ajax';
import { scrollWindowTo, scrollToTop } from 'widgets/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';

import { TButtonInstance } from 'widgets/global/Button';
import { TBasicInputInstance } from 'widgets/forms/BasicInput';

/**
 * @param BasicForm Base widget for extending
 * @returns Ajax Form class
 */
function AjaxFormClassCreator(BasicForm: import('widgets/forms/BasicForm').TBasicForm) {
    /**
     * @category widgets
     * @subcategory forms
     * @class AjaxForm
     * @augments BasicForm
     * @classdesc Represents AjaxForm component with next features:
     * 1. Allow submit and handle submit Form
     * 2. Allow handle server response
     * 3. Allow handle form errors (form level and field level) that comes from backend
     * AjaxForm widget should contain {@link Button} widgets that implement submit Form button.
     * Widget has next relationship:
     * * Handle Form submit using method {@link AjaxForm#handleSubmit} by event {@link Button#event:click} from {@link Button} widget.
     * @example <caption>Example of AjaxForm widget usage</caption>
     * <form
     *     action="${URLUtils.url('Account-SavePassword')}"
     *     method="POST"
     *     data-widget="ajaxform"
     *     data-event-submit.prevent="handleSubmit"
     * >
     *    <div
     *         class="b-form-message b-message m-error"
     *         hidden="hidden"
     *         data-ref="errorMessageLabel"
     *         role="alert"
     *     ></div>
     *
     *     ... form fields
     *
     *     <div>
     *         <button
     *              class="b-button"
     *              type="submit"
     *              name="save"
     *              data-widget="button"
     *              data-widget-event-click="handleSubmit"
     *              data-event-click.prevent="handleClick"
     *              data-id="submitButton"
     *         >
     *              ${Resource.msg('button.save','account',null)}
     *         </button>
     *     </div>
     * </form>
     * @property {string} data-widget - Widget name `ajaxform`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class AjaxForm extends BasicForm {
        submitting: boolean | undefined;

        /**
         * @description Marks form as busy
         */
        busy() {
            super.busy();
            this.getById(this.prefs().submitButton, (submitButton: TButtonInstance) => submitButton.busy());
        }

        /**
         * @description Marks form as not busy
         */
        unbusy() {
            super.unbusy();
            this.getById(this.prefs().submitButton, (submitButton: TButtonInstance) => submitButton.unbusy());
        }

        /**
         * @description Handles submit Form
         * @returns Promise object represents server response for shipping methods updating
         */
        handleSubmit() {
            if (!this.isChildrenValid() || this.submitting) {
                return Promise.resolve(null);
            }

            this.busy();

            this.submitting = true;

            this.ref(this.prefs().errorMessageLabel).hide();

            return submitFormJson(
                this.getFormUrl(),
                this.getFormFields(),
                this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
            )
                .then((response) => {
                    this.onSubmitted(response);

                    return response;
                })
                .catch(this.onError.bind(this))
                .finally(this.afterSubmission.bind(this));
        }

        /**
         * @description Handles server response
         * @emits AjaxForm#submit
         * @param data Server JSON response once form submitted
         */
        onSubmitted(data: TServerResponse) {
            if (data.success && data.redirectUrl) {
                window.location.assign(data.redirectUrl);
            } else if (!data.success) {
                if (data.redirectUrl) {
                    window.location.assign(data.redirectUrl);
                } else if (data.fieldErrors && Object.keys(data.fieldErrors).length) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMessage], index) => {
                        this.getById(name, (input: TBasicInputInstance) => {
                            input.setError(errorMessage);

                            // Set focus to the first invalid input field
                            if (index === 0) {
                                input.setFocus();
                            }
                        });
                    });
                } else if (data.errorCode !== 500) {
                    // Show only Accessibility Alert in case of 500 error
                    this.setError(data.errorMessage);
                    this.eachField((field) => field.clearError());
                    this.eventBus().emit('analytics.error.message', data.errorMessage);
                }
            } else {
                timeout(() => {
                    /**
                     * @description Event to submit AjaxForm
                     * @event AjaxForm#submit
                     */
                    this.emit('submit', data);
                });
            }
        }

        /**
         * @description Handles an error, which happens during request (for ex. 500 response)
         * @param error - error, which happened during request
         */
        onError(error: Error) {
            this.setError(error.message || errorFallbackMessage);
            const errorElement = this.ref(this.prefs().errorMessageLabel).get();

            if (errorElement) {
                scrollWindowTo(errorElement, true);
            } else {
                scrollToTop();
            }
        }

        /**
         * @description Changes Form state after submission
         */
        afterSubmission() {
            this.unbusy();

            this.submitting = false;
        }

        /**
         * @description Set Form or Form fields errors
         * @param msg - Generic error message, if no custom message - show generic one
         * @param scrollToError - defines using of scroll to error
         * @param refElem - RefElement id
         */
        setError(msg: string, scrollToError = true, refElem = 'errorMessageLabel') {
            const errorMessageLabel = this.ref(refElem)
                .setText(msg || '')
                .show();

            if (!scrollToError) {
                return;
            }

            const element = errorMessageLabel.get();

            if (element) {
                scrollWindowTo(element, true);
            } else {
                scrollToTop();
            }
        }
    }

    return AjaxForm;
}

export type TAjaxForm = ReturnType<typeof AjaxFormClassCreator>;

export type TAjaxFormInstance = InstanceType<TAjaxForm>;

export default AjaxFormClassCreator;

/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */

import widgetsMgr from 'widgets/widgetsMgr';
import ContextLoader from 'widgets/global/ContextLoader';
import ContentLoader from 'widgets/global/ContentLoader';

import Tabs from 'widgets/global/Tabs';
import TabPanel from 'widgets/global/TabPanel';
import Accordion from 'widgets/global/Accordion';
import AccordionItem from 'widgets/global/AccordionItem';
import Label from 'widgets/global/Label';
import Modal from 'widgets/global/Modal';
import GlobalModal from 'widgets/global/GlobalModal';
import ConfirmDialog from 'widgets/global/ConfirmDialog';
import GlobalAlerts from 'widgets/global/GlobalAlerts';
import Carousel from 'widgets/global/Carousel';
import BackToTop from 'widgets/global/BackToTop';
import EmitBusEvent from 'widgets/global/EmitBusEvent';
import StickyHeader from 'widgets/global/StickyHeader';
import Header from 'widgets/global/Header';
import ConnectionMonitor from 'widgets/global/ConnectionMonitor';

import ListAccessibility from 'widgets/global/ListAccessibility';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import AccessibilityFocusMixin from 'widgets/global/AccessibilityFocusMixin';
import SoftKeyboardToggleMixin from 'widgets/global/SoftKeyboardToggleMixin';
import FocusHighlighter from 'widgets/global/FocusHighlighter';
import Disclosure from 'widgets/global/Disclosure';

import Form from 'widgets/forms/Form';
import BasicForm from 'widgets/forms/BasicForm';
import AjaxForm from 'widgets/forms/AjaxForm';
import BasicInput from 'widgets/forms/BasicInput';
import InputEmail from 'widgets/forms/InputEmail';
import InputPassword from 'widgets/forms/InputPassword';
import InputCheckbox from 'widgets/forms/InputCheckbox';
import InputText from 'widgets/forms/InputText';
import InputTextarea from 'widgets/forms/InputTextarea';
import InputHidden from 'widgets/forms/InputHidden';
import InputTel from 'widgets/forms/InputTel';
import InputSelect from 'widgets/forms/InputSelect';
import InputRadio from 'widgets/forms/InputRadio';
import Button from 'widgets/global/Button';
import ButtonWithIcon from 'widgets/global/ButtonWithIcon';
import ProcessButton from 'widgets/global/ProcessButton';

import DismissBlock from 'widgets/global/DismissBlock';
import ConsentTracking from 'widgets/global/ConsentTracking';
import Geolocation from 'widgets/global/Geolocation';
import EmailSubscribe from 'widgets/forms/ajax/EmailSubscribe';
import Combobox from 'widgets/forms/Combobox';
import SearchBox from 'widgets/search/SearchBox';
import ProductTile from 'widgets/product/ProductTile';
import AlternativeImage from 'widgets/product/AlternativeImage';
import SwipeToClose from 'widgets/global/SwipeToClose';

widgetsMgr.addWidgetsList('global.widgets', () => [
    ['contextLoader', ContextLoader],
    ['contentLoader', ContentLoader],
    ['label', Label],
    ['carousel', Carousel],
    ['button', Button],
        ['buttonWithIcon', ButtonWithIcon, 'button'],
        ['processButton', ProcessButton, 'button'],
    ['listAccessibility', ListAccessibility],
        ['accordion', Accordion, 'listAccessibility'],
    ['accordionItem', AccordionItem],
    ['dismissBlock', DismissBlock],
    ['backtotop', BackToTop],
    ['consentTracking', ConsentTracking],
    ['geolocation', Geolocation],
    ['emitBusEvent', EmitBusEvent],
    ['focusHighlighter', FocusHighlighter],
    ['stickyHeader', StickyHeader],
    ['header', Header, 'contextLoader'],
    ['basicForm', BasicForm],
        ['form', Form, 'basicForm'],
            ['ajaxform', AjaxForm, 'basicForm'],
                ['emailSubscribe', EmailSubscribe, 'ajaxform'],
    ['basicInput', BasicInput],
        ['inputPassword', InputPassword, 'basicInput'],
        ['inputCheckbox', InputCheckbox, 'basicInput'],
        ['inputSelect', InputSelect, 'basicInput'],
        ['inputText', InputText, 'basicInput'],
            ['inputEmail', InputEmail, 'inputText'],
            ['inputTextarea', InputTextarea, 'inputText'],
        ['inputRadio', InputRadio, 'basicInput'],
        ['inputHidden', InputHidden, 'basicInput'],
    ['modal', Modal],
        ['modal', AccessibilityFocusTrapMixin, 'modal'],
        ['globalModal', GlobalModal, 'modal'],
        ['confirmDialog', ConfirmDialog, 'modal'],
    ['combobox', Combobox],
        ['searchBox', SearchBox, 'combobox'],
            ['searchBox', AccessibilityFocusTrapMixin, 'searchBox'],
            ['searchBox', SoftKeyboardToggleMixin, 'searchBox'],
            ['searchBox', Modal, 'searchBox'],
    ['tabs', Tabs],
    ['tabPanel', TabPanel],
    ['productTile', ProductTile],
        ['productTile', AlternativeImage, 'productTile'],
    ['disclosure', Disclosure],
        ['disclosure', AccessibilityFocusMixin, 'disclosure'],
    ['globalAlerts', GlobalAlerts],
    ['connectionMonitor', ConnectionMonitor]
]);

const hasContext = (context) => window.contexts.includes(context);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuPanel').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuPanelToggle').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuBarItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/HamburgerMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MegaMenu').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/header/MenuButton').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/cart/Minicart').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'widgets/cart/MinicartDialog').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/InputStepper').then(d => d.default)
    ]).then(deps => {
        const [
            MenuPanel,
            MenuPanelToggle,
            MenuBarItem,
            HamburgerMenuItem,
            MegaMenu,
            MenuButton,
            Minicart,
            MinicartDialog,
            InputStepper
        ] = deps;

        return {
            listId: 'header.widgets',
            widgetsDefinition: () => [
                ['menuPanelToggle', MenuPanelToggle],
                // listAccessibility
                    ['megaMenu', MegaMenu, 'listAccessibility'],
                    ['menuBarItem', MenuBarItem, 'listAccessibility'],
                    ['hamburgerMenuItem', HamburgerMenuItem, 'listAccessibility'],
                    ['menuButton', MenuButton, 'listAccessibility'],
                // swipeToClose
                ['swipeToClose', SwipeToClose],
                    ['menuPanel', MenuPanel, 'swipeToClose'],
                ['minicart', Minicart],
                // modal
                ['minicartDialog', MinicartDialog, 'modal'],
                ['inputStepper', InputStepper, 'basicInput']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => {
        if (window.sfccData?.countrySelectorType === 'list') {
            return Promise.all([
                import(/* webpackChunkName: 'header.widgets' */'widgets/header/CountrySelector').then(d => d.default),
                import(/* webpackChunkName: 'header.widgets' */'widgets/header/CountrySelectorMenuItem').then(d => d.default)
            ]).then(deps => {
                const [
                    CountrySelector,
                    CountrySelectorMenuItem
                ] = deps;

                return {
                    listId: 'header.countrySelector.widgets',
                    widgetsDefinition: () => [
                        ['countrySelector', CountrySelector],
                        ['countrySelectorMenuItem', CountrySelectorMenuItem, 'hamburgerMenuItem'],
                            ['countrySelectorMenuItem', CountrySelector, 'countrySelectorMenuItem']
                    ]
                };
            });
        } else {
            return import(/* webpackChunkName: 'header.widgets' */'widgets/forms/ajax/CountrySelectorForm').then(d => d.default)
                .then(CountrySelectorForm => {
                    return {
                        listId: 'header.countrySelector.widgets',
                        widgetsDefinition: () => [
                            ['countrySelectorForm', CountrySelectorForm, 'ajaxform']
                        ]
                    };
                });
        }
    }
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('content'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'content.widgets' */ 'widgets/content/ContactUs').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/MaskableInput').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/CountryCodeInput').then(d => d.default)
    ]).then(deps => {
        const [ContactUs, MaskableInput, CountryCodeInput] = deps;

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['contactUs', ContactUs],
                ['inputTel', InputTel, 'inputText'],
                    // @ts-ignore TS2339: Property 'sfccData' does not exist on type 'Window & typeof globalThis'.
                    ['inputTel', (window.sfccData?.isCountryCodeInputEnabled ? CountryCodeInput : MaskableInput), 'inputTel']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('home'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'homepage.widgets' */ 'widgets/global/HeroCarousel').then(d => d.default)
    ]).then(deps => {
        const [HeroCarousel] = deps;

        return {
            listId: 'homepage.widgets',
            widgetsDefinition: () => [
                ['heroCarousel', HeroCarousel]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddAllToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/RecentlyViewedMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductSet').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/BundleItem').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVideo').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductWithBonusMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVariationChangeMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddToCartStickyMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/forms/ajax/BackInStock').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductOptionMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/forms/InputStepper').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductAvailabilityMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductStickyPanel').then(d => d.default)
    ]).then(deps => {
        const [
            ProductDetail,
            AddAllToCartMixin,
            RecentlyViewedMixin,
            ProductSet,
            BundleItem,
            ProductVideo,
            AddToCartMixin,
            ProductWithBonusMixin,
            ProductVariationChangeMixin,
            AddToCartStickyMixin,
            BackInStock,
            ProductOptionMixin,
            InputStepper,
            ProductAvailabilityMixin,
            ProductStickyPanel
        ] = deps;

        return {
            listId: 'product.widgets',
            widgetsDefinition: () => [
                ['productDetail', ProductDetail],
                    ['productDetail', AddToCartMixin, 'productDetail'],
                    ['productDetail', ProductWithBonusMixin, 'productDetail'],
                    ['productDetail', ProductVariationChangeMixin, 'productDetail'],
                    ['productDetail', AddToCartStickyMixin, 'productDetail'],
                    ['productDetail', ProductOptionMixin, 'productDetail'],
                    // Used for backInStock functionality
                    ['productDetail', Disclosure, 'productDetail'],
                    ['productDetail', AccessibilityFocusMixin, 'productDetail'],
                    ['productDetail', RecentlyViewedMixin, 'productDetail'],
                    ['productDetail', ProductAvailabilityMixin, 'productDetail'],
                ['productSet', ProductSet],
                    ['productSet', AddAllToCartMixin, 'productSet'],
                    ['productSet', RecentlyViewedMixin, 'productSet'],
                ['bundleItem', BundleItem],
                    ['bundleItem', ProductVariationChangeMixin, 'bundleItem'],
                // basicForm / ajaxform
                ['backInStock', BackInStock, 'ajaxform'],
                ['productVideo', ProductVideo],
                ['inputStepper', InputStepper, 'basicInput'],
                ['productStickyPanel', ProductStickyPanel],
                ['addToCartMixin', AddToCartMixin]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('bonus'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'bonus.product.widgets' */ 'widgets/product/BonusProductMgr').then(d => d.default),
        import(/* webpackChunkName: 'bonus.product.widgets' */ 'widgets/product/BonusProductItem').then(d => d.default),
        import(/* webpackChunkName: 'bonus.product.widgets' */ 'widgets/product/ProductVariationChangeMixin').then(d => d.default),
        import(/* webpackChunkName: 'bonus.product.widgets' */ 'widgets/product/ProductVariantSelect').then(d => d.default)
    ]).then(deps => {
        const [
            BonusProductMgr,
            BonusProductItem,
            ProductVariationChangeMixin,
            ProductVariantSelect
        ] = deps;

        return {
            listId: 'bonus.product.widgets',
            widgetsDefinition: () => [
                ['bonusProductMgr', BonusProductMgr],
                ['bonusProduct', BonusProductItem],
                ['bonusProduct', ProductVariationChangeMixin, 'bonusProduct'],
                ['productVariantSelect', ProductVariantSelect]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp') && hasContext('carousel'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.carousel' */ 'widgets/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'widgets/product/ProductImagesCarousel').then(d => d.default),
        import(/* webpackChunkName: 'product.carousel' */ 'widgets/product/ProductVideoMixin').then(d => d.default)
    ]).then(deps => {
        const [
            ProductImages,
            ProductImagesCarousel,
            ProductVideoMixin
        ] = deps;

        return {
            listId: 'product.carousel.widgets',
            widgetsDefinition: () => [
                ['productImages', ProductImages],
                    ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                ['productImagesCarousel', ProductImagesCarousel, 'productImages'],
                    ['productImagesCarousel', ProductVideoMixin, 'productImagesCarousel']
            ]
        };
    })
});

/* Used ProductImagesGallery for md.lg.xl views and ProductImagesCarousel for sm view */
widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp') && hasContext('gallery'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.gallery' */ 'widgets/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.gallery' */ 'widgets/product/ProductImagesCarousel').then(d => d.default),
        import(/* webpackChunkName: 'product.gallery' */ 'widgets/product/ProductVideoMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.gallery' */ 'widgets/product/ProductImagesGallery').then(d => d.default)
    ]).then(deps => {
        const [
            ProductImages,
            ProductImagesCarousel,
            ProductVideoMixin,
            ProductImagesGallery
        ] = deps;

        return {
            listId: 'product.gallery.widgets',
            widgetsDefinition: () => [
                ['productImages', ProductImages],
                    ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                ['productImagesCarousel', ProductImagesCarousel, 'productImages'],
                    ['productImagesCarousel', ProductVideoMixin, 'productImagesCarousel'],
                ['productImagesGallery', ProductImagesGallery, 'productImages']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductVariantSelect').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductOptionSelect').then(d => d.default)
    ]).then(deps => {
        const [
            ProductVariantSelect,
            ProductOptionSelect
        ] = deps;

        return {
            listId: 'product.variation.widgets',
            widgetsDefinition: () => [
                ['productVariantSelect', ProductVariantSelect],
                ['productOptionSelect', ProductOptionSelect, 'productVariantSelect']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/ProductListingMgr').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementsPanel').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementsPanelToggle').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/Refinement').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/NoSearchResultsForm').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/StickyPanel').then(d => d.default)
    ]).then(deps => {
        const [
            ProductListingMgr,
            RefinementsPanel,
            RefinementsPanelToggle,
            RefinementMenuItem,
            Refinement,
            NoSearchResultsForm,
            StickyPanel
        ] = deps;

        return {
            listId: 'search.widgets',
            widgetsDefinition: () => [
                // listAccessibility
                    ['refinementsPanel', Modal, 'listAccessibility'],
                    ['refinementsPanel', SwipeToClose, 'refinementsPanel'],
                    ['refinementsPanel', RefinementsPanel, 'refinementsPanel'],
                    ['refinementMenuItem', RefinementMenuItem, 'listAccessibility'],
                // tabs
                ['productListingMgr', ProductListingMgr, 'tabs'],
                ['refinementsPanelToggle', RefinementsPanelToggle],
                ['refinement', Refinement],
                ['stickyPanel', StickyPanel],
                // basicForm
                    ['noSearchResultsForm', NoSearchResultsForm, 'basicForm']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('subscription'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.subscriptions' */ 'widgets/account/SubscriptionsForm').then(d => d.default),
        import(/* webpackChunkName: 'account.subscriptions' */ 'widgets/forms/InputCheckboxGroup').then(d => d.default)
    ]).then(deps => {
        const [
            SubscriptionsForm,
            InputCheckboxGroup
        ] = deps;

        return {
            listId: 'account.subscriptions',
            widgetsDefinition: () => [
                ['subscriptionsForm', SubscriptionsForm, 'ajaxform'],
                ['inputCheckboxGroup', InputCheckboxGroup, 'basicInput']
            ]
        };
    })
});
widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PaymentsList').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddPaymentMethodForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/checkout/InputCreditCardNumber').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/MaskableInput').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/CountryCodeInput').then(d => d.default)
    ]).then(deps => {
        const [
            PaymentsList,
            AddPaymentMethodForm,
            InputCreditCardNumber,
            MaskableInput,
            CountryCodeInput
        ] = deps;

        return {
            listId: 'account.payment.widgets',
            widgetsDefinition: () => [
                ['paymentsList', PaymentsList],
                ['addPaymentMethodForm', AddPaymentMethodForm, 'ajaxform'],
                ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput'],
                ['inputTel', InputTel, 'inputText'],
                    // @ts-ignore TS2339: Property 'sfccData' does not exist on type 'Window & typeof globalThis'.
                    ['inputTel', (window.sfccData?.isCountryCodeInputEnabled ? CountryCodeInput : MaskableInput), 'inputTel']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddressList').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/OrderHistoryMgr').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/GoogleAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default)
    ]).then(deps => {
        const [
            AddressList,
            OrderHistoryMgr,
            PasswordResetForm,
            AddressForm,
            AutocompleteField,
            GoogleAutocompleteField,
            SessionExpiration
        ] = deps;

        return {
            listId: 'account.widgets',
            widgetsDefinition: () => [
                ['addressList', AddressList],
                ['orderHistoryMgr', OrderHistoryMgr],
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / ajaxform
                    ['addressForm', AddressForm, 'ajaxform'],
                    ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                // inputText
                ['autocompleteField', AutocompleteField, 'inputText'],
                    ['googleAutocompleteField', GoogleAutocompleteField, 'autocompleteField']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AjaxFormResponseContainer').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AjaxFormResponseContainerMigrated').then(d => d.default)
    ]).then(deps => {
        const [
            LoginForm,
            AjaxFormResponseContainer,
            AjaxFormResponseContainerMigrated
        ] = deps;

        return {
            listId: 'login.account.widgets',
            widgetsDefinition: () => [
                ['loginForm', LoginForm, 'ajaxform'],
                ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
                ['ajaxFormResponseContainerMigrated', AjaxFormResponseContainerMigrated, 'modal']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CartMgr').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CartWithBonusMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/CouponForm').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CheckoutButtons').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/InputStepper').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default)
    ]).then(deps => {
        const [
            CartMgr,
            CartWithBonusMixin,
            CouponForm,
            CheckoutButtons,
            SessionExpiration,
            InputStepper,
            RadioSelector
        ] = deps;

        return {
            listId: 'cart.widgets',
            widgetsDefinition: () => [
                ['cartMgr', CartMgr],
                    ['cartMgr', CartWithBonusMixin, 'cartMgr'],
                ['checkoutButtons', CheckoutButtons],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / form / ajaxForm
                    ['couponform', CouponForm, 'ajaxform'],
                ['inputStepper', InputStepper, 'basicInput'],
                // inputRadio
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/CheckoutLoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CustomerRegistration').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItem').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemCREDIT_CARD').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemZERO_PAYMENT').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/global/SessionExpiration').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/content/ContactUs').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/MaskableInput').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/CountryCodeInput').then(d => d.default)
    ]).then(deps => {
        const [
            LoginForm,
            CheckoutLoginForm,
            CustomerRegistration,
            PaymentAccordion,
            PaymentAccordionItem,
            PaymentAccordionItemCREDITCARD,
            PaymentAccordionItemZEROPAYMENT,
            SessionExpiration,
            ContactUs,
            MaskableInput,
            CountryCodeInput
        ] = deps;

        return {
            listId: 'checkout.widgets',
            widgetsDefinition: () => [
                // basicForm / form / ajaxform
                ['loginForm', LoginForm, 'ajaxform'],
                    ['checkoutLoginForm', CheckoutLoginForm, 'loginForm'],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // accordion
                    ['paymentAccordion', PaymentAccordion, 'accordion'],
                // accordionItems
                    ['paymentAccordionItem', PaymentAccordionItem, 'accordionItem'],
                        ['paymentAccordionItemCREDIT_CARD', PaymentAccordionItemCREDITCARD, 'paymentAccordionItem'],
                            ['paymentAccordionItemCREDIT_CARD', AccessibilityFocusMixin, 'paymentAccordionItemCREDIT_CARD'],
                        ['paymentAccordionItemZERO_PAYMENT', PaymentAccordionItemZEROPAYMENT, 'paymentAccordionItem'],
                ['contactUs', ContactUs],
                ['inputTel', InputTel, 'inputText'],
                    // @ts-ignore TS2339: Property 'sfccData' does not exist on type 'Window & typeof globalThis'.
                    ['inputTel', (window.sfccData?.isCountryCodeInputEnabled ? CountryCodeInput : MaskableInput), 'inputTel'],
                ['customerRegistration', CustomerRegistration]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutMgr').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/ShippingStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutSummaryMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/SummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/BillingStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CustomerStepMixin').then(d => d.default)
    ]).then(deps => {
        const [
            CheckoutMgr,
            ShippingStepMixin,
            CheckoutSummaryMixin,
            CheckoutStepMixin,
            SummaryStepMixin,
            BillingStepMixin,
            CustomerStepMixin
        ] = deps;

        return {
            listId: 'checkout.manager.widgets',
            widgetsDefinition: () => [
                ['checkoutMgr', CheckoutMgr],
                    ['checkoutMgr', AccessibilityFocusMixin, 'checkoutMgr'],
                    ['checkoutMgr', CheckoutStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', ShippingStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', CheckoutSummaryMixin, 'checkoutMgr'],
                    ['checkoutMgr', SummaryStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', BillingStepMixin, 'checkoutMgr'],
                    ['checkoutMgr', CustomerStepMixin, 'checkoutMgr']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/AddressCheckoutForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/ShippingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/BillingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/InputCreditCardNumber').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/GoogleAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/global/ViewMore').then(d => d.default)
    ]).then(deps => {
        const [
            PasswordResetForm,
            AddressCheckoutForm,
            ShippingForm,
            BillingForm,
            InputCreditCardNumber,
            RadioSelector,
            AutocompleteField,
            GoogleAutocompleteField,
            AddressForm,
            ViewMore
        ] = deps;

        return {
            listId: 'checkout.form.widgets',
            widgetsDefinition: () => [
                ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                ['addressForm', AddressForm, 'ajaxform'],
                    ['addressCheckoutForm', AddressCheckoutForm, 'addressForm'],
                        ['addressCheckoutForm', AccessibilityFocusMixin, 'addressCheckoutForm'],
                            ['addressCheckoutForm', ViewMore, 'addressCheckoutForm'],
                        ['shippingForm', ShippingForm, 'addressCheckoutForm'],
                        ['billingForm', BillingForm, 'addressCheckoutForm'],
                // basicInput
                ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput'],
                ['autocompleteField', AutocompleteField, 'inputText'],
                    ['googleAutocompleteField', GoogleAutocompleteField, 'autocompleteField'],
                // inputRadio
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AjaxFormResponseContainer').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AjaxFormResponseContainerMigrated').then(d => d.default)
    ]).then(deps => {
        const [
            AjaxFormResponseContainer,
            AjaxFormResponseContainerMigrated
        ] = deps;

        return {
            listId: 'login.checkout.widgets',
            widgetsDefinition: () => [
                ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
                ['ajaxFormResponseContainerMigrated', AjaxFormResponseContainerMigrated, 'modal']
            ]
        };
    })
});

/* sonar-ignore-end */

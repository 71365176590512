import { RefElement } from 'widgets/toolbox/RefElement';
import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns SwipeToClose widget
 */
function SwipeToCloseClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class SwipeToClose
     * @augments Widget
     * @classdesc Represents SwipeToClose component that implement logic for closing panel by swipe gesture.
     * It has next features:
     * 1. Allow set panel close direction (left/right)
     * 2. Handle touch events for smooth panel closing
     *
     * @example <caption>Example of SwipeToClose widget usage with left drag direction</caption>
     * <div
     *    id="main-navigation"
     *    class="b-menu_panel"
     *    aria-labelledby="main-navigation-toggle"
     *    data-widget="swipeToClose"
     *    data-panel-container="panelContainer"
     *    data-drag-direction-x="left"
     * >
     *      <div
     *          data-ref="dialog"
     *          aria-labelledby="main-navigation-toggle"
     *          class="b-menu_panel-inner"
     *          data-event-touchstart.sm.md="handleTouchStart"
     *      >
     *          <div data-ref="panelContainer"
     *              class="b-menu_subpanel m-active_level_1"
     *              data-event-transitionstart.sm.md.prevent="transitionStart"
     *              data-event-transitionend.sm.md.prevent="transitionEnd"
     *           >
     *              ... widget content
     *          </div>
     *      </div>
     * </div>
     * @property {string} data-widget - Widget name `swipeToClose`
     * @property {string} data-menu - reference id of child element (inner container), value of its data-ref attribute
     * @property {string} data-panel-container - reference id of child element (panels wrapper), value of its data-ref attribute
     * @property {string} data-drag-direction-x - whether closing swipe should be performed right to left (value 'left') or left to right (value 'right').
     */
    class SwipeToClose extends Widget {
        dragDirectionX = 0;

        touchMoveDisposable?: Array<() => void>;

        touchEndDisposable?: Array<() => void>;

        touchCancelDisposable?: Array<() => void>;

        startTime = 0;

        isMoving = false;

        startX = 0;

        startY = 0;

        currentX = 0;

        currentY = 0;

        isOpen = false;

        menuWidth = 0;

        lastX = 0;

        lastY = 0;

        moveX = 0;

        dragDirection?: string;

        prefs() {
            return {
                menu: 'dialog',
                panelContainer: 'panelContainer',
                dragDirectionX: 'left',
                ...super.prefs()
            };
        }

        /**
         * @description Widget logic initialization
         */
        init() {
            super.init();
            this.dragDirectionX = this.prefs().dragDirectionX === 'left' ? 1 : -1;
        }

        /**
         * @description TouchStart Event handler
         * @listens dom#touchstart
         * @param _ Source of keydown event
         * @param event  Event object
         */
        handleTouchStart(_: HTMLElement, event: TouchEvent) {
            this.touchMoveDisposable = this.ev('touchmove', this.handleTouchMove, this.ref(this.prefs().menu).get());
            this.touchEndDisposable = this.ev('touchend', this.handleTouchEnd, this.ref(this.prefs().menu).get());
            this.touchCancelDisposable = this.ev('touchcancel', this.handleTouchCancel, this.ref(this.prefs().menu).get());
            this.startTime = new Date().getTime();
            const coordinateX = event.touches[0].pageX || 0;
            const coordinateY = event.touches[0].pageY || 0;

            this.isMoving = false;
            this.startX = coordinateX;
            this.startY = coordinateY;
            this.currentX = coordinateX;
            this.currentY = coordinateY;
            this.touchStart(this.startX, this.startY);
        }

        /**
         * @description TouchMove Event handler
         * @listens dom#touchmove
         * @param _ Source of keydown event
         * @param event  Event object
         */
        handleTouchMove(_: HTMLElement, event: TouchEvent) {
            this.isMoving = true;
            this.currentX = event.touches[0].pageX;
            this.currentY = event.touches[0].pageY;

            const translateX = this.currentX - this.startX;
            const translateY = this.currentY - this.startY;

            this.touchMove(event, this.currentX, this.currentY, translateX, translateY);
        }

        /**
         * @description TouchEnd Event handler
         * @listens dom#touchend
         */
        handleTouchEnd() {
            const translateX = this.dragDirectionX * (this.currentX - this.startX);
            const translateY = this.currentY - this.startY;
            const timeTaken = (new Date().getTime() - this.startTime);

            this.touchEnd(this.currentX, this.currentY, translateX, translateY, timeTaken);
        }

        /**
         * @description TouchCancel Event handler
         * @listens dom#touchcancel
         */
        handleTouchCancel() {
            this.disposableListeners();
        }

        /**
         * @description Preparing panel to move
         * @param startX x coordinate of where the finger is placed in the DOM
         * @param startY  y coordinate of where the finger is placed in the DOM
         */
        touchStart(startX: number, startY: number) {
            const menu = this.ref(this.prefs().menu);

            this.isOpen = menu !== null;
            this.toggleTransition(menu, true);
            this.toggleTransition(this.ref(this.prefs().panelContainer), true);
            const menuNode = menu.get();

            this.menuWidth = menuNode ? menuNode.offsetWidth : 0;
            this.lastX = startX;
            this.lastY = startY;

            if (this.isOpen) {
                this.moveX = 0;
            } else {
                this.moveX = -this.menuWidth;
            }

            this.dragDirection = '';
        }

        /**
         * @description Update UI
         */
        updateUi() {
            if (this.isMoving) {
                const element = this.ref(this.prefs().menu).get();

                if (element) {
                    element.style.transform = 'translateX(' + this.moveX + 'px)';
                }

                window.requestAnimationFrame(this.updateUi.bind(this));
            }
        }

        /**
         * @description Toggle menu
         * @param translateX x coordinate of where the finger is placed in the viewport
         */
        toggleMenu(translateX: number) {
            const menu = this.ref(this.prefs().menu);
            const menuNode = menu.get();

            if (menuNode) {
                menuNode.style.transform = '';
            }

            this.toggleTransition(menu, false);

            if (translateX < 0 || !this.isOpen) {
                this.closePanel();
                this.isOpen = false;
            } else {
                this.isOpen = true;
            }
        }

        /**
         * @description Open Menu Panel.Interface can be override
         * @param data data
         */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        openPanel(data: {[key: string]: string|boolean}) {
            throw new Error('Method not implemented.');
        }

        /**
         * @description Close Menu Panel.Interface can be override
         */
        closePanel() {
            throw new Error('Method not implemented.');
        }

        /**
         * @description Toggle transition class
         * @param element DOM element
         * @param isTransitionOn transition flag
         */
        toggleTransition(element: RefElement, isTransitionOn: boolean) {
            if (isTransitionOn) {
                element.addClass('m-no_transition');
            } else {
                element.removeClass('m-no_transition');
            }
        }

        /**
         * @description calculate opacity
         * @returns New opacity value
         */
        calculateOpacity(): number {
            const percentageBeforeDiff = (Math.abs(this.moveX) * 100) / this.menuWidth;
            const percentage = 100 - percentageBeforeDiff;

            return (percentage / 100);
        }

        /**
         * @description calculate opacity
         * @param element Ref element
         * @param opacity New opacity value
         */
        setBackdropOpacity(element: RefElement, opacity: string | number) {
            const htmlElement = element.get();

            if (!htmlElement) {
                return;
            }

            htmlElement.style.setProperty('--backdrop-opacity', opacity.toString());
        }

        /**
         * @description Disposable listeners
         */
        disposableListeners() {
            if (this.touchMoveDisposable) {
                this.touchMoveDisposable.forEach(disposable => disposable());
                delete this.touchMoveDisposable;
            }

            if (this.touchEndDisposable) {
                this.touchEndDisposable.forEach(disposable => disposable());
                delete this.touchEndDisposable;
            }

            if (this.touchCancelDisposable) {
                this.touchCancelDisposable.forEach(disposable => disposable());
                delete this.touchCancelDisposable;
            }
        }

        /**
         * @description calculate new position.Set drag direction
         * @param event Event object
         * @param currentX current X position
         * @param currentY current Y position
         * @param translateX new position X value
         * @param translateY new position Y value
         */
        touchMove(event: Event, currentX: number, currentY: number, translateX: number, translateY: number) {
            if (!this.dragDirection) {
                if (Math.abs(translateX) >= Math.abs(translateY)) {
                    this.dragDirection = 'horizontal';
                } else {
                    this.dragDirection = 'vertical';
                }

                window.requestAnimationFrame(this.updateUi.bind(this));
            }

            if (this.dragDirection === 'vertical') {
                this.lastX = currentX;
                this.lastY = currentY;
            } else {
                const movedPath = this.dragDirectionX * (this.moveX + (currentX - this.lastX));

                if (movedPath < 0 && movedPath > -this.menuWidth) {
                    this.moveX += currentX - this.lastX;
                }

                this.lastX = currentX;
                this.lastY = currentY;
                this.setBackdropOpacity(this.ref('html'), this.calculateOpacity());
            }
        }

        /**
         * @description Open/Close panel based on position. Remove transition classes. Remove event listeners.
         * @param currentX current X position
         * @param currentY current Y position
         * @param translateX new position X value
         * @param translateY new position Y value
         * @param timeTaken time diff between touch start and touch end
         */
        touchEnd(currentX: number, currentY: number, translateX: number, translateY: number, timeTaken: number) {
            const velocity = 0.3;

            if (this.isMoving && this.isOpen) {
                this.isMoving = false;

                if (this.dragDirection === 'horizontal') {
                    const isMovedLessThanHalfOfTheMenu = (translateX < (-this.menuWidth) / 2);
                    const isSwipeMotion = (Math.abs(translateX) / timeTaken > velocity);

                    if (isMovedLessThanHalfOfTheMenu || isSwipeMotion) {
                        this.toggleMenu(translateX);
                    } else {
                        this.toggleMenu(0);
                    }
                }
            }

            this.toggleTransition(this.ref(this.prefs().menu), false);
            this.toggleTransition(this.ref(this.prefs().panelContainer), false);
            this.setBackdropOpacity(this.ref('html'), '');
            this.disposableListeners();
        }
    }

    return SwipeToClose;
}

export type TSwipeToClose = ReturnType<typeof SwipeToCloseClassCreator>;

export type TSwipeToCloseInstance = InstanceType<TSwipeToClose>;

export default SwipeToCloseClassCreator;

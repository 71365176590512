/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('comparison'),
    load: () => Promise.all([
            import(/* webpackChunkName: 'comparison.widgets' */ './widgets/comparison/Comparison').then(d => d.default),
            import(/* webpackChunkName: 'search.comparison' */ './widgets/search/ComparisonCheckbox').then(d => d.default)
    ])
        .then(deps => {
            const [
                Comparison,
                ComparisonCheckbox
            ] = deps;

            return {
                listId: 'comparison.widgets',
                widgetsDefinition: () => [
                    ['comparison', Comparison],
                    ['comparisonCheckbox', ComparisonCheckbox]
                ]
            };
        })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.comparison' */ './widgets/search/ComparisonBucket').then(d => d.default),
        import(/* webpackChunkName: 'comparison.widgets' */ './widgets/search/ComparisonListingMixin').then(d => d.default),
        import(/* webpackChunkName: 'search.comparison' */ './widgets/search/ComparisonCheckbox').then(d => d.default)
    ]).then(deps => {
        const [
            ComparisonBucket,
            ComparisonListingMixin,
            ComparisonCheckbox
        ] = deps;

        return {
            listId: 'search.comparison',
            widgetsDefinition: () => [
                ['comparisonBucket', ComparisonBucket],
                ['comparisonCheckbox', ComparisonCheckbox],
                ['productListingMgr', ComparisonListingMixin, 'productListingMgr']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),

    load: () => Promise.all([

        import(/* webpackChunkName: 'product.comparison' */ './widgets/product/AddToCompareMixin').then(d => d.default)
    ]).then(deps => {
        const [
            AddToCompareMixin
        ] = deps;

        return {
            listId: 'product.comparison',
            widgetsDefinition: () => [
                ['productDetail', AddToCompareMixin, 'productDetail']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'./widgets/header/ComparisonLink').then(d => d.default)
    ]).then(deps => {
        const [
            ComparisonLink
        ] = deps;

        return {
            listId: 'header.comparison',
            widgetsDefinition: () => [
                ['comparisonLink', ComparisonLink]
            ]
        };
    })
});

import widgetsMgr from 'widgets/widgetsMgr';

const hasContext = (context) => window.contexts.includes(context);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('eGiftCertificate'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ './widgets/forms/ajax/EGiftCertificate').then(d => d.default),
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ 'widgets/forms/InputNumber').then(d => d.default),
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ 'widgets/global/PresetSwitcher').then(d => d.default),
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ './widgets/product/GiftCertificateDetail').then(d => d.default),
        import(/* webpackChunkName: 'egiftcertificate.widgets' */ 'widgets/product/RecentlyViewedMixin').then(d => d.default)
    ]).then(deps => {
        const [EGiftCertificate, InputNumber, PresetSwitcher, GiftCertificateDetail, RecentlyViewedMixin] = deps;

        return {
            listId: 'egiftcertificate.widgets',
            widgetsDefinition: () => [
                ['eGiftCertificateForm', EGiftCertificate, 'ajaxform'],
                ['presetSwitcher', PresetSwitcher],
                ['inputNumber', InputNumber, 'basicInput'],
                ['giftCertificateDetail', GiftCertificateDetail, 'productDetail'],
                ['giftCertificateDetail', RecentlyViewedMixin, 'giftCertificateDetail']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => { return hasContext('account') || hasContext('gift-card'); },
    load: () => Promise.all([
        import(/* webpackChunkName: 'egiftcertificate.checkbalance.widgets' */ './widgets/forms/EGiftCertificateCheckBalance')
            .then(d => d.default)
    ]).then(deps => {
        const [EGiftCertificateCheckBalance] = deps;

        return {
            listId: 'egiftcertificate.checkbalance.widgets',
            widgetsDefinition: () => [
                ['eGiftCertificateCheckBalance', EGiftCertificateCheckBalance, 'ajaxform']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.egiftcertificate.widgets' */ './widgets/checkout/PaymentMethodGiftCertificate')
            .then(d => d.default),
        import(/* webpackChunkName: 'checkout.egiftcertificate.widgets' */ './widgets/checkout/GiftCertificatesBillingForm')
            .then(d => d.default)
    ]).then(deps => {
        const [PaymentMethodGiftCertificate, GiftCertificatesBillingForm] = deps;

        return {
            listId: 'egiftcertificate.method.widgets',
            widgetsDefinition: () => [
                ['paymentMethodGiftCertificate', PaymentMethodGiftCertificate],
                ['billingForm', GiftCertificatesBillingForm, 'billingForm']
            ]
        };
    })
});

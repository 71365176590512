import { scrollElementTo } from 'widgets/toolbox/scroll';
import { getViewType } from 'widgets/toolbox/viewtype';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { timeout } from 'widgets/toolbox/util';
import { TWidget } from 'widgets/Widget';
import { TRefElementInstance } from 'widgets/toolbox/RefElement';

const DEFAULT_DIRECTION = 'horizontal';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @param Widget Base widget for extending
 * @returns Carousel class
 */
function CarouselClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Carousel
     * @augments Widget
     * @classdesc Represents Carousel component with next features:
     * 1. Allow to set carousel direction based on the view type
     * 2. Allow to use pagination for carousel rendered by mustache template
     * 3. Allow to scroll carousel to the next/previous/custom( index can be passed to the method) page index
     * 4. Allow to scroll by on the page click(can be used for carousel with thumbnails)
     * 5. Allow to scroll to custom element's position
     * 6. Allow to scroll to focused element
     * 7. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices.
     * Also we can control carouse with keyboard
     * 8. Allow to get carousel images
     *
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example <caption>Example of Carousel widget usage</caption>
     * <div
     *     class="b-carousel"
     *     data-widget="carousel"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="b-carousel-ctrl ${isRTL ? 'm-next' : 'm-prev'}"
     *         tabindex="-1"
     *         aria-hidden="true"
     *         title="${Resource.msg(isRTL ? 'button.previous' : 'button.next', 'common', null)}"
     *         data-ref="elemPrevButton"
     *         data-event-click="${isRTL ? 'scrollToNextPage' : 'scrollToPrevPage'}"
     *         data-tau="carousel_prev"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselLeft" />
     *     </button>
     *     <button
     *         class="b-carousel-ctrl ${isRTL ? 'm-prev' : 'm-next'}"
     *         tabindex="-1"
     *         aria-hidden="true"
     *         title="${Resource.msg(isRTL ? 'button.next' : 'button.previous', 'common', null)}"
     *         data-ref="elemNextButton"
     *         data-event-click="${isRTL ? 'scrollToPrevPage' : 'scrollToNextPage'}"
     *         data-tau="carousel_next"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselRight" />
     *     </button>
     *     <div
     *         class="b-carousel-track"
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll.passive="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="product" status="loopState">
     *             <div class="b-carousel-item" data-tau="carousel_tile">
     *                 <isobject object="${product}" view="recommendation">
     *                     <isinclude url="${URLUtils.url('Tile-Show', 'pid', product.ID, 'viewType', 'productCarousel')}"/>
     *                 </isobject>
     *             </div>
     *         </isloop>
     *     </div>
     * </div>
     */
    class Carousel extends Widget {
        currentPage = 0;

        pagination: Promise<HTMLElement> | undefined;

        scrollingTimeoutValue = 60;

        grabbingTimeoutValue = 200;

        delta = 0;

        roundingDelta = 1;

        carouselDirection = DEFAULT_DIRECTION;

        carouselDimension = 0;

        initialCoordinate = 0;

        initialScrollPosition = 0;

        isScrollStart: boolean | undefined;

        isScrollEnd: boolean | undefined;

        isNavButtonClicked: boolean | undefined;

        mouseMoveDisposable?: Array<() => void> | undefined;

        mouseLeaveDisposable?: Array<() => void> | undefined;

        scrollingTimeout: NodeJS.Timeout | undefined;

        grabbingRemoveTimeout: NodeJS.Timeout | undefined;

        isCallInNextFrameRequested: boolean | undefined;

        visibleItems = 1;

        contentWidth = 0;

        howToScroll = 'one';

        prefs() {
            return {
                elemPrevButton: 'elemPrevButton',
                elemNextButton: 'elemNextButton',
                elemCarouselTrack: 'elemCarouselTrack',
                classesInited: 'm-inited',
                pageCurrentClass: 'm-current',
                grabbingClass: 'm-grabbing',
                mouseMoveNavigationClass: 'm-mousemove_navigation',
                direction: {
                    small: 'horizontal',
                    medium: 'horizontal',
                    large: 'horizontal',
                    extraLarge: 'horizontal'
                },
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
            // Async loading to not block other widget init
            timeout(() => {
                cssLoadChecker.get().then(() => this.initCarousel());
            }, 0);
        }

        /**
         * @description Initial carousel configuration
         * @listens "viewtype.change"
         */
        initCarousel() {
            this.defineCarouselDirection();
            this.eventBus().on('viewtype.change', 'onViewtypeChange');
            this.ev('focusin', this.handleScrollToFocusedItem, this.ref(this.prefs().elemCarouselTrack).get());

            this.onScroll();
            this.updateCarouselState();
            this.initPagination();
            this.setActivePagination();
            this.getCarouselDimension();

            this.onCarouselLoaded();
            this.ref('self').addClass(this.prefs().classesInited);
        }

        /**
         * @description Update carousel. Used on PDP variation change
         * @returns Carousel instance
         */
        update(): Carousel {
            this.updateCarouselMetric();
            this.updateCarouselState();
            this.initPagination();
            this.setActivePagination();

            return this;
        }

        onCarouselLoaded() {
            // Placeholder for additional logic on carousel loading
        }

        /**
         * @description Executed when widget is re-rendered
        */
        onRefresh() {
            super.onRefresh();

            this.update();
            this.ref('self').addClass(this.prefs().classesInited);
        }

        /**
         * @description Viewtype change event handler. Recalculates carousel dimension and redefines carousel direction.
         */
        onViewtypeChange() {
            this.defineCarouselDirection();
            this.getCarouselDimension();
            this.updateCarouselMetric();
            this.updateCarouselState();
        }

        /**
         * @description Scroll carousel to the next page
         * @listens dom#click
         */
        scrollToNextPage() {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack?.scrollBy(this.carouselDimension, 0);
            } else {
                elemCarouselTrack?.scrollBy(0, this.carouselDimension);
            }

            this.isNavButtonClicked = true;
        }

        /**
         * @description Scroll carousel to the previous page
         * @listens dom#click
         */
        scrollToPrevPage() {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack?.scrollBy(-this.carouselDimension, 0);
            } else {
                elemCarouselTrack?.scrollBy(0, -this.carouselDimension);
            }

            this.isNavButtonClicked = true;
        }

        /**
         * @description Scroll carousel to the page index
         * @param pageIndex Page index scroll to
         * @returns Carousel instance
         */
        scrollToPage(pageIndex: number): Carousel {
            const pageStarPoint = Math.round(this.carouselDimension * pageIndex);

            if (this.carouselDirection === 'horizontal') {
                this.scrollTo(0, pageStarPoint);
            } else {
                this.scrollTo(pageStarPoint, 0);
            }

            return this;
        }

        /**
         * @description Scroll element to point
         * @param top top position
         * @param left left position
         */
        scrollTo(top: number, left: number) {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (elemCarouselTrack) {
                scrollElementTo(elemCarouselTrack, top, left);
            }
        }

        /**
         * @description On scroll we update carousel controls state
         */
        onScroll() {
            this.updateCarouselMetric();

            if (!this.isCallInNextFrameRequested) {
                window.requestAnimationFrame(this.updateDuringScroll.bind(this));

                this.isCallInNextFrameRequested = true;
            }
        }

        /**
         * @description On carousel scroll end handler
         * @emits Carousel#pagechanged
         */
        onScrollEnd() {
            /**
             * @description Event to carousel page change
             * @event Carousel#pagechanged
             */
            const updatedPageIndex = this.getCurrentPageIndex();

            if (updatedPageIndex !== this.currentPage) {
                this.currentPage = updatedPageIndex;
                this.emit('pagechanged', updatedPageIndex);
            }

            if (this.isNavButtonClicked) {
                if (this.isScrollStart) {
                    this.ref(this.prefs().elemNextButton).focus();
                }

                if (this.isScrollEnd) {
                    this.ref(this.prefs().elemPrevButton).focus();
                }

                this.isNavButtonClicked = false;
            }
        }

        /**
         * @description Update carousel during scroll
         */
        updateDuringScroll() {
            this.updateCarouselState();

            if (this.pagination) {
                this.setActivePagination();
            } else {
                this.initPagination();
            }

            if (this.scrollingTimeout) {
                clearTimeout(this.scrollingTimeout);
            }

            this.scrollingTimeout = setTimeout(() => this.onScrollEnd(), this.scrollingTimeoutValue);

            this.isCallInNextFrameRequested = false;
        }

        /**
         * @description Update carousel state
         */
        updateCarouselState() {
            const carousel = this.ref('self');
            const elemPrevButton = this.ref(this.prefs().elemPrevButton);
            const elemNextButton = this.ref(this.prefs().elemNextButton);

            carousel.toggleClass('m-no_scroll', this.hasNoScroll());

            if (this.isScrollStart) {
                carousel.removeClass('m-prev_visible');
                elemPrevButton.attr('disabled', '');
            } else {
                carousel.addClass('m-prev_visible');
                elemPrevButton.attr('disabled', false);
            }

            if (this.isScrollEnd) {
                carousel.removeClass('m-next_visible');
                elemNextButton.attr('disabled', '');
            } else {
                carousel.addClass('m-next_visible');
                elemNextButton.attr('disabled', false);
            }
        }

        /**
         * @description Update carousel metric
         */
        updateCarouselMetric() {
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            const carouselElem = this.ref('self').get();

            const roundingDelta = this.roundingDelta;

            if (carouselTrackElem && carouselElem) {
                if (this.carouselDirection === 'horizontal') {
                    const totalScrollWidth = carouselTrackElem ? Math.abs(carouselTrackElem.scrollLeft) + carouselElem.offsetWidth : 0;

                    // We are comparing with 1 instead of 0 as Chrome has a problem calculating the width with RTL text direction
                    this.isScrollStart = Math.abs(carouselTrackElem.scrollLeft) <= 1;
                    this.isScrollEnd = (totalScrollWidth + roundingDelta) >= carouselTrackElem.scrollWidth;
                } else {
                    const totalScrollHeight = carouselTrackElem.scrollTop + carouselElem.offsetHeight;

                    this.isScrollStart = carouselTrackElem.scrollTop <= 0;
                    this.isScrollEnd = (totalScrollHeight + roundingDelta) >= carouselTrackElem.scrollHeight;
                }
            }
        }

        /**
         * @description Method to get carousel dimension (width or height) depending on carousel direction
         * @returns width or height of carousel
         */
        getCarouselDimension(): number {
            const carouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!carouselTrack) {
                return 0;
            }

            this.carouselDimension = this.carouselDirection === 'horizontal'
                ? carouselTrack.clientWidth
                : carouselTrack.clientHeight;

            return this.carouselDimension;
        }

        getCurrentScrollPosition(): number {
            const carouselTrack = this.ref('elemCarouselTrack').get();

            if (!carouselTrack) {
                return 0;
            }

            return this.carouselDirection === 'horizontal' ? carouselTrack.scrollLeft : carouselTrack.scrollTop;
        }

        /**
         * @description set carousel direction property from widget config
         */
        defineCarouselDirection() {
            this.carouselDirection = this.prefs().direction[getViewType()] || DEFAULT_DIRECTION;
        }

        /**
         * @description Check if carousel has no scroll
         * @returns indicated is carousel has no scroll
         */
        hasNoScroll(): boolean {
            return Boolean(this.isScrollStart && this.isScrollEnd);
        }

        // Swipe / Drag
        // =====================================================================

        /**
         * @description Initialize mouse move handling on mouse down
         * @listens dom#mousedown
         * @param el source of event
         * @param event event instance in DOM
         */
        onMouseDown(el: TRefElementInstance, event: MouseEvent) {
            this.initialCoordinate = this.getCurrentCoordinate(event);
            this.initialScrollPosition = this.getCurrentScrollPosition();

            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            elemCarouselTrack.classList.add(this.prefs().mouseMoveNavigationClass);

            this.mouseMoveDisposable = this.ev<HTMLElement, MouseEvent>('mousemove', this.onMouseMove, elemCarouselTrack);
            this.mouseLeaveDisposable = this.ev<HTMLElement, MouseEvent>('mouseleave', this.onMouseUp, elemCarouselTrack);

            if (this.grabbingRemoveTimeout) {
                clearTimeout(this.grabbingRemoveTimeout);
            }
        }

        /**
         * @description Handle Mouse/Touch move
         * @listens dom#mousemove
         * @listens dom#touchmove
         * @param element HTMLElement
         * @param event DOM event
         */
        onMouseMove(element: HTMLElement, event: TouchEvent | MouseEvent) {
            const currentCoordinate = this.getCurrentCoordinate(event);

            if (!this.initialCoordinate || this.initialCoordinate === currentCoordinate) {
                return;
            }

            this.delta = (this.initialCoordinate - currentCoordinate);

            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            elemCarouselTrack.classList.add(this.prefs().grabbingClass);

            if (!this.delta) {
                return;
            }

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack.scrollLeft = this.initialScrollPosition + this.delta;
            } else {
                elemCarouselTrack.scrollTop = this.initialScrollPosition + this.delta;
            }

            // console.log(this.delta, elemCarouselTrack.scrollLeft)
        }

        /**
         * @description Mouseup event handler
         * @listens dom#mouseup
         */
        onMouseUp() {
            if (this.mouseMoveDisposable) {
                this.mouseMoveDisposable.forEach(disposable => disposable());
                this.mouseMoveDisposable = undefined;
            }

            if (this.mouseLeaveDisposable) {
                this.mouseLeaveDisposable.forEach(disposable => disposable());
                this.mouseLeaveDisposable = undefined;
            }

            // we should remove scroll-snap-type with delay, otherwise it cause bouncing
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack);

            this.grabbingRemoveTimeout = setTimeout(
                () => elemCarouselTrack.removeClass([
                    this.prefs().grabbingClass,
                    this.prefs().mouseMoveNavigationClass
                ]),
                this.grabbingTimeoutValue
            );

            this.delta = 0;
        }

        /**
         * @description Method to get event coordinate depending on carousel direction
         * @param event event object
         * @returns event coordinate
         */
        getCurrentCoordinate(event: MouseEvent | TouchEvent) {
            let currentCoordinate = 0;

            if (event instanceof MouseEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.clientX
                    : event.clientY;
            } else if (event instanceof TouchEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.touches[0].pageX
                    : event.touches[0].pageY;
            }

            return currentCoordinate;
        }

        // Pagination
        // =====================================================================

        /**
         * @description Pagination init logic
         */
        initPagination() {
            if (this.hasNoScroll()) {
                return;
            }

            this.has('pagination', paginationRefEl => {
                const pagination = paginationRefEl.get();

                if (pagination) {
                    // If empty pagination - we need to render it. Otherwise - create.
                    if (pagination.innerHTML === '') {
                        this.createPaginationElements();
                    } else {
                        this.pagination = Promise.resolve(pagination);
                    }
                }
            });
        }

        /**
         * @description Pagination click handler
         * @listens dom#click
         * @param el source of event
         */
        handlePaginationClick(el: TRefElementInstance) {
            let pageIndex = el.data('page') as unknown as number;
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            const sliderCount = carouselTrackElem ? carouselTrackElem.childElementCount : 0;

            if (this.howToScroll === 'all') {
                pageIndex = Number(pageIndex) * this.visibleItems;

                if (pageIndex >= sliderCount) {
                    pageIndex = sliderCount - this.visibleItems;
                }
            }

            if (pageIndex !== null) {
                this.scrollToPage(parseInt(pageIndex + '', 10));
            }

            this.update();
        }

        /**
         * @description Carousel page click handler
         * @param el page element
         * @listens dom#click
         * @emits Carousel#pageclicked
         */
        onPageClick(el: TRefElementInstance) {
            if (this.delta === 0) {
                /**
                 * @description Event to carousel page click
                 * @event Carousel#pageclicked
                 */
                this.emit('pageclicked', el.data('page'));
            }
        }

        /**
         * @description Create Pagination elements for carousel
         */
        createPaginationElements() {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            const fullWidth = elemCarouselTrack?.scrollWidth;
            let scrollWidth = elemCarouselTrack?.clientWidth;
            let padding = 1;

            if (this.howToScroll === 'one') {
                padding -= this.visibleItems;
            } else {
                scrollWidth = elemCarouselTrack.children[0].clientWidth * this.visibleItems;
                padding = 0;
            }

            const numberOfPages = Math.round(fullWidth / scrollWidth) + padding;
            const pagination = new Array(numberOfPages).fill(0).map((_el, i) => ({ page: i }));

            this.pagination = new Promise((resolve) => {
                this.render(undefined, { pagination }, this.ref('pagination')).then(() => {
                    // @ts-ignore possibly undefined
                    return resolve(this.ref('pagination').get());
                });
            });
        }

        /**
         * @description Set active pagination
         */
        setActivePagination() {
            if (!this.pagination) {
                return;
            }

            this.pagination.then((pagination: HTMLElement) => {
                if (!pagination) {
                    return;
                }

                const currentPageIndex = this.getCurrentPageIndex();
                const currentPageNode = pagination.children[currentPageIndex];

                if (!currentPageNode) {
                    this.initPagination();
                }

                pagination.children[this.currentPage || 0]?.classList.remove(
                    this.prefs().pageCurrentClass
                );

                currentPageNode?.classList.add(this.prefs().pageCurrentClass);

                this.currentPage = currentPageIndex;
            });
        }

        /**
         * @description Get current carousel page index
         * @returns Current carousel page index
         */
        getCurrentPageIndex(): number {
            this.updateCarouselMetric();
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            let scrollWidth = carouselTrackElem?.scrollWidth;
            const visibleItemCount = this.visibleItems;
            const carouselPadding = 10;

            if (!(carouselTrackElem instanceof HTMLElement)) {
                return 0;
            }

            const currentPosition = this.carouselDirection === 'horizontal'
                ? carouselTrackElem.scrollLeft + this.contentWidth : carouselTrackElem.scrollTop;

            if (this.howToScroll === 'one') {
                scrollWidth = carouselTrackElem.children[0].clientWidth;
            } else {
                scrollWidth = carouselTrackElem.children[0].clientWidth * visibleItemCount;
            }

            const pageDimension = this.carouselDirection === 'horizontal'
                ? scrollWidth : carouselTrackElem.clientHeight;

            return Math.floor((currentPosition + carouselPadding || 0) / (pageDimension || 1));
        }

        // Pagination methods related to PDP gallery
        // =====================================================================

        /**
         * @description Adds some defined class on carousel inner elements
         * @param pageIndex - element index
         * @returns carousel instance
         */
        markCurrentPage(pageIndex: number): Carousel {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            [].slice.call((elemCarouselTrack?.children)).forEach((element: HTMLElement) => {
                const dataPage = parseInt(((element && element.getAttribute('data-page')) || '0'), 10);

                element.classList.toggle(this.prefs().pageCurrentClass, dataPage === pageIndex);
            });

            return this;
        }

        /**
         * @description Scrolls active element into carousel viewport.
         * This might be useful when using dependent carousels, like on PDP images/thumbnails
         * @returns carousel instance
         */
        scrollIntoView(): Carousel {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return this;
            }

            // @ts-ignore possibly undefined current element
            const currentElement: HTMLElement = [].find.call(
                elemCarouselTrack.children,
                (element: HTMLElement) => {
                    return element.classList.contains(this.prefs().pageCurrentClass);
                }
            );

            if (!currentElement) {
                return this;
            }

            if (this.carouselDirection === DEFAULT_DIRECTION) {
                if ((currentElement.offsetLeft + currentElement.clientWidth)
                    > (elemCarouselTrack.scrollLeft + elemCarouselTrack.clientWidth)
                    || (currentElement.offsetLeft < elemCarouselTrack.scrollLeft)) {
                    this.scrollTo(0, currentElement.offsetLeft);
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if ((currentElement.offsetTop + currentElement.clientHeight)
                    > (elemCarouselTrack.scrollTop + elemCarouselTrack.clientHeight)
                    || (currentElement.offsetTop < elemCarouselTrack.scrollTop)) {
                    this.scrollTo(currentElement.offsetTop, 0);
                }
            }

            return this;
        }

        /**
         * @description Programmatically handle scroll to focused carousel item.
         * This is the fix for Chrome bug where it could not calculate item boundaries properly
         * and threat item like it into view. The bug is on PDP Gallery.
         * Applicable only for horizontal scroll.
         */
        handleScrollToFocusedItem() {
            if (this.carouselDirection !== 'horizontal') { return; }

            const track = this.ref(this.prefs().elemCarouselTrack).get();
            const activeItem = document.activeElement;

            if (!activeItem || !(activeItem instanceof HTMLElement) || !track) {
                return;
            }

            const activeItemLeft = activeItem.getBoundingClientRect().left;
            const activeItemRight = activeItemLeft + activeItem.getBoundingClientRect().width;
            const trackStart = track.getBoundingClientRect().left;
            const trackEnd = trackStart + track.clientWidth;

            if (activeItemRight >= trackEnd) {
                track.scrollBy(activeItemRight - trackEnd, 0);
            } else if (activeItemLeft <= trackStart) {
                track.scrollBy(activeItemLeft - trackStart, 0);
            }
        }

        /**
         * @description Next/Prev Buttons keyboard handler. Used only on PDP gallery
         * we need this method to prevent Zoom opened on Enter and able to focus carousel pagination
         * @listens dom#keydown
         * @param element dom node that trigger the event
         * @param event  Event object
         */
        handleKeydown(element: TRefElementInstance, event: KeyboardEvent) {
            if (event.keyCode === keyCode.RETURN || event.keyCode === keyCode.SPACE) {
                event.stopPropagation();
            }
        }
    }

    return Carousel;
}

export type TCarousel = ReturnType<typeof CarouselClassCreator>;

export type TCarouselInstance = InstanceType<TCarousel>;

export default CarouselClassCreator;

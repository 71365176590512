import { TWidget } from 'widgets/Widget';
import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';
import { WishlistItemsMgr } from '../toolbox/wishlistItemsMgr';
const ALERT_EVENT = 'alert.show';
const WISHLIST_UPDATE_EVENT = 'wishlist.updated';
const ADD_TO_FAVORITES = 'add.to.favorites'
const wishlistItemsMgr = WishlistItemsMgr.getInstance();

import { TProduct } from 'types/models/product';
import { TProductDetail } from 'widgets/product/ProductDetail';
import { TProductTile } from 'widgets/product/ProductTile';
import { TLabelInstance } from 'widgets/global/Label';
import { TButtonWithIconInstance } from 'widgets/global/ButtonWithIcon';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

type TWishlistItem = {
    id: string;
    optionId: string;
    selectedValueId: string;
};

type TResponse = TServerResponse & {
    itemAlreadyExists: boolean;
    msg: string;
    pid: string;
    wishlistItems: Array<TWishlistItem>;
};
type TProductOption = {
    id: string;
    selectedValueId: string;
    customOptionValue: string | null;
    values: Array<{
        isCustomOption: boolean;
        isCustomOptionSelected: boolean;
    }>;
};
type TRemovingItemResponse = {
    success: boolean;
    pid: string;
    wishlistItems: Array<TWishlistItem>;
    itemsCount: number;
}

declare global {
    interface ExtendedWindow extends Window {
        sfccData: Window['sfccData'] & {
            wishlistActionUrls: {
                getItems: string;
                addToWishlist: string;
                removeFromWishlist: string;
            };
        };
    }
}

declare const window: ExtendedWindow;
/**
 * @param className the class name to add to the tooltip for shown or hidden state
 */
function handleTooltip(className) {
    let wishListBtns = document.getElementsByClassName('keepsake-icon');
    let wishListBtn;
    let element;
    for (let i = 0, j = wishListBtns.length; i < j; i++) {
        element = wishListBtns[i] as HTMLElement;
        if (element.dataset.id === 'addToKeepsakeWishlist') {
            wishListBtn = element as HTMLElement;
        }
    }
    // grab the icon for the correct PLP tile or PDP button to set to add or remove state
    let tooltipElement = wishListBtn.querySelector('.b-wishlist_button-icon') as HTMLElement || wishListBtn.querySelector('.b-wishlist_button-text') as HTMLElement;
    let pdpTooltipElement = wishListBtn.parentElement.querySelector('.b-tooltip.m-right.m-keepsake') as HTMLElement ;

    tooltipElement.classList.add(className);
    if (pdpTooltipElement) {
        pdpTooltipElement.classList.add(className);
    }
}
/**
 * @param Widget Base widget for extending
 * @returns Add to Wishlist Mixin class
 */
function AddToWishlistMixinClassCreator <Type extends TWidget> (Widget: Type extends TProductDetail ? TProductDetail : TProductTile) {
    /**
     * @category widgets
     * @subcategory plugin_wishlist
     * @class AddToWishlistMixin
     * @augments Widget
     * @classdesc Add to wishlist mixin with next features:
     * 1. Represents `Add to wishlist` button together with related functionality
     * <br>*As far as it's a mixin, separate DOM widget is not needed.*
     * 2. Allows to add products to wishlist from PDP, QuickView
     * 3. Communicates to user operation results in both cases: error and success
     *
     * Widget has next relationship:
     * * Listens click on `add button` that instance of {@link Button} to handle addition to wish list.
     * <br>Also uses component methods to change button text, activate/deactivate button
     * * Uses methods of `add message` that instance of {@link Label} to set/hide/show messsage
     * @property {string} data-pid - product id
     * @property {string} [data-text-network-error=Network Error] - Network error message
     * @property {string} [data-add-to-wishlist-msg=addToWishlistMsg] - ID of {@link Label} component that represents `add msg`
     * @property {string} [data-add-to-wishlist-btn=addToWishlist] -  ID of {@link Button} component that represents `add button`
     * @property {string} data-text-added-to-wishlist - Added to wishlist message
     * @property {boolean} [add-to-wishlist-hide-texts=false] - Added to wishlist message
     * @property {string} data-original-wishlist-button-message - original message for `add to wishlist` button
     * @property {boolean} data-show-alert-if-product-in-wishlist - Indicates, if we need to show active icon for wishlist button,
     * in case if request returns, that item already in wishlist
     * @property {string} [data-class-added-to-wishlist] - Added to wishlist class
     */
    class AddToWishlistMixin extends Widget {
        prefs() {
            return {
                pid: '',
                selectedQuantity: '',
                textNetworkError: 'Network Error',
                addToWishlistMsg: 'addToWishlistMsg',
                addToWishlistBtn: 'addToWishlist',
                addToKeepsakeWishlistMsg: 'addToKeepsakeWishlistMsg',
                addToKeepsakeWishlistBtn: 'addToKeepsakeWishlist',
                textAddedToWishlist: '',
                textAddedToKocWishlist: '',
                addToWishlistHideTexts: false,
                originalWishlistButtonMessage: '',
                originalKocWishlistButtonMessage: '',
                showAlertIfProductInWishlist: false,
                getWishlistItemsUrl: '',
                isKeepsake: false,
                accessibilityAlerts: {
                    addedtowishlist: '',
                    removedfromwishlist: '',
                    addedtokeepsakewishlist: '',
                    removedfromkeepsakewishlist: ''
                },
                productOptions: <Array<TProductOption>>[],
                adobeAnalytics: {
                    productSKU: '',
                    productName: '',
                    productURL: '',
                    currency: '',
                    basePrice: '',
                    priceAfterDiscount: '',
                    size: '',
                    color: '',
                    buyableChannel: '',
                },
                productDeliveryOptions: <Array<{id: string}>>[],
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();
            this.updateWishListButtonState();
            this.eventBus().on(WISHLIST_UPDATE_EVENT, 'updateStateWishlistButton');
        }

        /**
         * @description Change wishlist icon state on widget update
         */
        onRefresh() {
            this.updateWishListButtonState()
        }

        updateWishListButtonState() {
            var productTileContext = this instanceof this.getConstructor('productTile');

            if (!productTileContext && this.prefs().isKeepsake) {
                // For product detail page we update state for both buttons Favorites and Keepsake wish list
                wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions, this.prefs().isKeepsake)
                .then((state: boolean) => {
                    this.changeStateWishlistButton(state, this.prefs().isKeepsake);
                });

                wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions, false)
                .then((state: boolean) => {
                    this.changeStateWishlistButton(state, false);
                });
            } else {
            wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions, this.prefs().isKeepsake)
            .then((state: boolean) => {
                this.changeStateWishlistButton(state, this.prefs().isKeepsake);
            });
            }
        }



        /**
         * @description Activate Wish List button
         * @param addToWishlistBtn Target Wish List button
         */
        activateWishListButton(addToWishlistBtn: TButtonWithIconInstance) {
            addToWishlistBtn
                .activate();

            if (!this.prefs().addToWishlistHideTexts) {
                const addedButtonText = addToWishlistBtn.config.isKeepsake ? this.prefs().textAddedToKocWishlist : this.prefs().textAddedToWishlist;
                addToWishlistBtn.setText(addedButtonText);
            }

            if (addToWishlistBtn.prefs().labelRemove) {
                addToWishlistBtn.ref('self').get()?.setAttribute('aria-label', addToWishlistBtn.prefs().labelRemove);
            }
        }

        /**
         * @description Deactivate Wish List button
         * @param addToWishlistBtn Target Wish List button
         */
        deactivateWishListButton(addToWishlistBtn: TButtonWithIconInstance) {
            addToWishlistBtn
                .deactivate()
                .enable();

            if (!this.prefs().addToWishlistHideTexts) {
                const originalButtonText = addToWishlistBtn.config.isKeepsake ? this.prefs().originalKocWishlistButtonMessage : this.prefs().originalWishlistButtonMessage;
                addToWishlistBtn.setText(originalButtonText);
            }

            if (addToWishlistBtn.prefs().labelAdd) {
                addToWishlistBtn.ref('self').get()?.setAttribute('aria-label', addToWishlistBtn.prefs().labelAdd);
            }
        }

        /**
         * @description Update state Wish List button after some actions
         * @param updatedProductId Product ID that was updated in the Wish List
         */
        updateStateWishlistButton(updatedProductId: string) {
            if (!updatedProductId) { return; }

            this.getById(this.prefs().addToWishlistBtn, (addToWishlistBtn: TButtonWithIconInstance) => {
                if (addToWishlistBtn.config.productId !== updatedProductId) { return; }

                wishlistItemsMgr.isItemInWishlist(updatedProductId)
                    .then((state: boolean) => {
                        if (state && !addToWishlistBtn.isActive()) {
                            this.activateWishListButton(addToWishlistBtn);
                        }

                        if (!state && addToWishlistBtn.isActive()) {
                            this.deactivateWishListButton(addToWishlistBtn);
                        }
                    });
            });
        }

        /**
         * @description Change state of Add to Wishlist button
         * @param state Ids product currently in the wishlist
         */
        changeStateWishlistButton(state: boolean, isKeepsake: boolean) {
            const hideKeepsake = localStorageWrapper.getItem('hideKeepsake');

            if (isKeepsake && hideKeepsake === 'true') {
                this.setPref('isKeepsake', false);

                this.getById(this.prefs().addToKeepsakeWishlistBtn, (addToKeepsakeWishlistBtn: TButtonWithIconInstance) => {
                    addToKeepsakeWishlistBtn.hide();
                    handleTooltip('m-hidden');
                });

                this.getById(this.prefs().addToWishlistBtn, (addToWishlistBtn: TButtonWithIconInstance) => {
                    addToWishlistBtn.show();

                    if (state) {
                        this.activateWishListButton(addToWishlistBtn);
                    } else {
                        this.deactivateWishListButton(addToWishlistBtn);
                    }
                });
            } else if (isKeepsake && hideKeepsake === 'false') {
                this.getById(this.prefs().addToKeepsakeWishlistBtn, (addToKeepsakeWishlistBtn: TButtonWithIconInstance) => {
                    addToKeepsakeWishlistBtn.show();
                    handleTooltip('m-shown');
                    if (state) {
                        this.activateWishListButton(addToKeepsakeWishlistBtn);
                    } else {
                        this.deactivateWishListButton(addToKeepsakeWishlistBtn);
                    }
                });

            } else if (!isKeepsake) {
                this.getById(this.prefs().addToWishlistBtn, (addToWishlistBtn: TButtonWithIconInstance) => {
                    addToWishlistBtn.show();

                    if (state) {
                        this.activateWishListButton(addToWishlistBtn);
                    } else {
                        this.deactivateWishListButton(addToWishlistBtn);
                    }
                });
            }
        }

        /**
         * @description Removes product from wish list.Change button state
         * @param button Target button
         */
        removeProductFromWishlist(button: TButtonWithIconInstance) {
            button.busy();
            const removeProductFromWishlistUrl = window.sfccData.wishlistActionUrls?.removeFromWishlist;

            getJSONByUrl(removeProductFromWishlistUrl, {
                pid: this.prefs().pid || '',
                isKeepsake: button.config.isKeepsake?.toString()
            }).then((response) => {
                const removingItemResponse = <TRemovingItemResponse> response;

                if (!response.success) {
                    return;
                }

                this.changeStateWishlistButton(false, !!button.config.isKeepsake);
                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit(ALERT_EVENT, {
                    accessibilityAlert: !!button.config.isKeepsake
                        ? this.prefs().accessibilityAlerts.removedfromkeepsakewishlist
                        : this.prefs().accessibilityAlerts.removedfromwishlist,
                    type: 'error'
                });
                wishlistItemsMgr.updateWishlistItems(removingItemResponse.wishlistItems, !!button.config.isKeepsake);
                this.eventBus().emit('product.removedFromWishlist', removingItemResponse.wishlistItems.length > 0, false, !!button.config.isKeepsake);
                this.eventBus().emit(WISHLIST_UPDATE_EVENT, response.pid);
            })
                .finally(() => {
                    button.unbusy();
                });
        }

        /**
         * @description Handle click on the button
         * @listens dom#click
         * @param button Target button
         */
        wishlistButtonClickHandler(button: TButtonWithIconInstance) {
            if (button.isActive()) {
                this.removeProductFromWishlist(button);
            } else {
                this.addToWishlist(button);
            }
        }

        /**
         * @description afterUpdateProduct handler
         * @param response Response object
         * @emits "product.updated"
         */
        afterUpdateProduct(response) {
            // @ts-expect-error Method is executed only in scope of ProductDetail widget
            super.afterUpdateProduct(response);

            if (response.wishlistItems) {
                wishlistItemsMgr.updateWishlistItems(response.wishlistItems);
            }
        }

        /**
         * @description Update Product View
         * @param product - Product object
         */
        updateProductView(product: TProduct) {
            // @ts-expect-error Method is executed only in scope of ProductDetail widget
            super.updateProductView(product);
            this.renderWishListButton(product);
        }

        /**
         * @description Show error/success message
         * @param addButton - Target button
         * @listens dom#click
         */
        addToWishlist(addButton: TButtonWithIconInstance) {
            const addToWishlistUrl = window.sfccData.wishlistActionUrls?.addToWishlist;
            const options = this.prefs().productOptions;
            let customOptionValue = '';
            let optionId = '';
            let optionVal = '';

            if (options && options.length) {
                optionId = options[0].id;
                optionVal = options[0].selectedValueId;

                if (this.mixedToProductDetails()) {
                    if (this.isCustomOptionValid(options[0].id)) {
                        customOptionValue = <string> this.getCustomOptionValue(options[0].id);
                    } else {
                        return null;
                    }
                }
            }

            addButton.busy();
            this.getById(this.prefs().addToWishlistMsg, (labelAddToWishlistMsg: TLabelInstance) => labelAddToWishlistMsg.hide());
            return submitFormJson(addToWishlistUrl, {
                pid: this.prefs().pid,
                qty: this.prefs().selectedQuantity,
                optionId: optionId,
                optionVal: optionVal,
                customOptionValue: customOptionValue,
                isKeepsake: !!addButton.config.isKeepsake
            })
                .then((response: TResponse) => {
                    this.handleSuccess(response);

                    return response;
                })
                .catch(() => {
                    /**
                     * @description Global event to show alert
                     * @event "alert.error"
                     */
                        this.eventBus().emit('alert.error', {
                        accessibilityAlert: this.prefs().textNetworkError,
                        type: 'error'
                    });
                })
                .finally(() => {
                    addButton.unbusy();
                });
        }

        mixedToProductDetails(): this is InstanceType<TProductDetail> {
            const ProductDetail = <TProductDetail|TWidget> this.getConstructor('productDetail');
            const BaseWidget = this.getConstructor('widget');

            return (ProductDetail !== BaseWidget && this instanceof ProductDetail);
        }

        /**
         * @description Handle success response
         * @param response - Response object
         */
        handleSuccess(response: TResponse) {
            const addToWishlistBtnId = response.isKeepsake ? this.prefs().addToKeepsakeWishlistBtn : this.prefs().addToWishlistBtn;
            const addToWishlistBtn = <TButtonWithIconInstance> this.getById(addToWishlistBtnId, (element) => element);

            if (!addToWishlistBtn) {
                return;
            }

            if (response.success) {
                this.activateWishListButton(addToWishlistBtn);
                const accessibilityAlert = !!response.isKeepsake
                ? this.prefs().accessibilityAlerts.addedtokeepsakewishlist
                : this.prefs().accessibilityAlerts.addedtowishlist;

                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit(ALERT_EVENT, {
                    accessibilityAlert,
                    type: 'success'
                });
            } else if (response.itemAlreadyExists) {
                this.deactivateWishListButton(addToWishlistBtn);

                if (this.prefs().showAlertIfProductInWishlist) {
                    /**
                     * @description Global event to show alert
                     * @event "alert.error"
                     */
                    this.eventBus().emit('alert.error', {
                        accessibilityAlert: response.errorMessage
                    });
                }
            } else {
                /**
                 * @description Global event to show alert
                 * @event "alert.error"
                 */
                this.eventBus().emit('alert.error', { errorCode: 500 });
            }

            wishlistItemsMgr.updateWishlistItems(response.wishlistItems, !!response.isKeepsake);
            this.eventBus().emit('product.addedToWishlist', response.wishlistItems.length > 0, true, !!response.isKeepsake);
            this.eventBus().emit(WISHLIST_UPDATE_EVENT, response.pid);

            // prepare data for emit adobe analytics event
            const deliveryOptions = {
                shipToMe: 'address',
                signAndSend: 'mail',
                storePickUp: 'store'
            }

            const productDeliveryOptions = this.prefs().productDeliveryOptions || [];
            const availableForPurchase = productDeliveryOptions.map(option => option.id ? deliveryOptions[option.id] : '').join(' | ');
            const initiallyCreated = response.wishlistItems.length === 1;
            const listStatus = response.success || response.itemAlreadyExists ? 'Add Success' : 'Add Attempt';

            const {
                productSKU,
                productName,
                productURL,
                currency,
                basePrice,
                priceAfterDiscount,
                size,
                color,
                buyableChannel
            } = this.prefs().adobeAnalytics;
            /**
             * @description Global event to send adobe analytics
             * @event "add.to.favorites"
             */
            this.eventBus().emit(ADD_TO_FAVORITES, {
                initiallyCreated,
                AddToFavInfo: {
                    listType: response.isKeepsake ? 'Keepsake Wish List' : 'Favorites',
                    listStatus,
                },
                productDetail: {
                    productSKU,
                    productName,
                    productURL,
                    currency,
                    basePrice,
                    priceAfterDiscount,
                    ...(size && { size }),
                    ...(color && { color }),
                    buyableChannel,
                    availableForPurchase
                }
            });
        }

        /**
         * @description Method to revert initial state for `Add to Wishlist` button and messages
         * Usually used when re-rendering PDP/QuickView after variation change
         *
         * @param product Product object
         */
        renderWishListButton(product: TProduct) {
            this.setPref('pid', product.id);
            wishlistItemsMgr.isItemInWishlist(<string>product.id, product.options)
                .then((state: boolean) => {
                    this.changeStateWishlistButton(state, product.isKeepsake);
                });
        }
    }

    return AddToWishlistMixin;
}

export type TAddToWishlistMixin = ReturnType<typeof AddToWishlistMixinClassCreator>;

export type TAddToWishlistMixinInstance = InstanceType<TAddToWishlistMixin>;

export default AddToWishlistMixinClassCreator;

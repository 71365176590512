/**
* @param cart <object>
* @param productInfo <object>
* @description determine current shipping info from cart
*/
export function getShippingInfo(cart, productInfo) {
    const shippingInfo = {
        shipmentType: cart.totals.shippingMethodName,
        shipmentPrice: cart.totals.totalShippingCostObject ? cart.totals.totalShippingCostObject.value.toFixed(2) : Number(0).toFixed(2),
        shipmentLocation: 'One Location'
    };

    if (productInfo) {
        const item = cart.items.find((product) => product.id === productInfo.productSKU);

        if (item) {
            const shipment = cart.shipments.find((shipping) => shipping.deliveryType === item.deliveryType);

            if (shipment) {
                const shippingMethod = shipment.shippingMethods.find((methods) => methods.ID === shipment.selectedShippingMethod);

                shippingInfo.shipmentType = shippingMethod ? shippingMethod.displayName : null;
                shippingInfo.shipmentPrice = shippingMethod ? shippingMethod.shippingCost.priceFinalValue.toFixed(2) : Number(0).toFixed(2);
            }
        }
    }

    return shippingInfo;
}

/**
* @description determine current page name
*/
export function getCurrentPageName() {
    return window.cachedPageData && window.cachedPageData.page && window.cachedPageData.page.pageType;
}

/**
* @description separate refinements to refinementNames and refinementValues arrays
* @param url - url link
*/
export function getUrlRefinements(url) {
    const urlParams = new URLSearchParams(url);
    const refinementNames: Array<string> = [];
    const refinementValues: Array<string> = [];

    Array.from<[string, string]>(urlParams).forEach(([key, value]) => {
        if (key.includes('prefn')) {
            refinementNames.push(value);
        } else if (key.includes('prefv')) {
            refinementValues.push(value);
        }
    });

    return { refinementNames, refinementValues };
}

/**
* @description remove values from target array
* @param valuesToRemove - array with values that have to be removed
* @param targetArray - target Array for remove values from
*/
export function getUniqueArray(valuesToRemove, targetArray) {
    valuesToRemove.forEach((prefRefVal) => {
        targetArray = targetArray.filter(refVal => refVal !== prefRefVal);
    });

    return targetArray;
}

/**
* @description determine search total count
*/
export function getSearchTotalCount() {
    return window.cachedPageData && window.cachedPageData.page && window.cachedPageData.page.searchTotalCount;
}

/**
* @param productInfo <Array>
* @description determine current product set sku from cart
*/
export function getProductSetSKU(productInfo) {
    let setSKU;

    productInfo.some((item) => {
        if (item.productSetSKU) {
            setSKU = item.productSetSKU;
        }

        return item.productSetSKU;
    });

    return setSKU;
}

/**
* @param totals <object>
* @description determine coupon details
*/
export function getPromoCardApplied(totals) {
    const promoCardApplied: Array<any> = [];

    if (totals.coupon && totals.couponAmount) {
        promoCardApplied.push({
            promoCardCode: totals.coupon,
            promoCardStatus: 'Success',
            promoCardAmount: -totals.couponAmount
        });
    }

    return promoCardApplied;
}

/**
* @param typeKOC boolean
* @param paymentMethodName string
* @param selectedPayments Array
* @description determine checkout flow
*/
export function getCheckoutFlow(typeKOC, paymentMethodName, selectedPayments) {
    let flow = 'Checkout-Regular';

    if (typeKOC && typeKOC === 'New') {
        flow = 'KOC Purchase Membership';
    } else if (typeKOC && typeKOC === 'Renew') {
        flow = 'KOC Renew Membership';
    } else if (paymentMethodName && paymentMethodName === 'PayPal') {
        flow = 'Checkout-Paypal';
    }

    if (selectedPayments && selectedPayments.length) {
        selectedPayments.forEach((payment) => {
            if (payment.name === 'PayPal') {
                flow = 'Checkout-Paypal';
            }
        });
    }

    return flow;
}

/**
* @param productInfo <Array<any>>
* @description update location identifier
*/
export function updateProductInfoLocation(productInfo) {
    const currentPage = getCurrentPageName();
    productInfo.forEach((product) => {
        const productLocation = product.productLocation;
        if (productLocation && productLocation.fullTitle) {
            product.locationIndentifier = productLocation.title;
        } else if (productLocation && !productLocation.fullTitle){
            product.locationIndentifier = currentPage + productLocation.title;
        } else {
            product.locationIndentifier = currentPage;
        }
    });

    return productInfo;
}

/**
* @param productInfo <Array<any>>
* @param productModel <object>
* @description update location identifier
*/
export function updateSelectedDeliveryOption(productInfo, productModel) {
    if (productModel.deliveryType) {
        productInfo.shipmentMethodDisplayed = productModel.deliveryType;

        if (productModel.deliveryOptions && productModel.deliveryOptions.length) {
            productModel.deliveryOptions.some((option) => {
                if (option.id === productModel.deliveryType) {
                    productInfo.buyableChannelOption = option.id;
                    productInfo.buyableChannel = option.name;
                }

                return option.id === productModel.deliveryType;
            });
        }
    }

    return productInfo;
}

/**
* @param productInfo <Array<any>>
* @param productModel <object>
* @description update location identifier
*/
export function updateAvailability(productInfo, productModel) {
    if (productModel && productModel.availability) {
        productInfo.productAvailableinInventory = productModel.availability.quantityAvailableToSell;
        productInfo.productAvailable = productModel.availability.messages && productModel.availability.messages.length
            ? productModel.availability.messages[0] : undefined;
    }

    return productInfo;
}

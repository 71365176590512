import { TInputHidden } from 'widgets/forms/InputHidden';

/**
 * @description Google Recaptcha Widget Implementation
 * @param InputHidden Base widget for extending
 */
function GoogleRecaptchaClassCreator(InputHidden: TInputHidden) {
    /**
     * @category widgets
     * @subcategory forms
     * @class GoogleRecaptcha
     * @augments TInputHidden
     * @classdesc Google Recaptcha field component with next features:
     * 1. Loads Google Recaptcha script and render recaptcha
     * 2. Executes recaptcha on field validation
     * 3. Handles errors and render error message
     * 4. Handles recaptcha token expiration
     * @property {string} data-widget - Widget name `googleRecaptcha`
     * @property {string} data-site-key - Site key for the Google Recaptcha API
     * @property {string} data-recaptcha-error-message - Error message
     * @property {boolean} data-recaptcha-enabled - Determines if Recaptcha functionality enabled
     * @example <caption>Example of GoogleRecaptcha widget markup</caption>
     * <form
     *     ...
     *     method="POST"
     *     data-widget="ajaxform"
     *     data-event-submit.prevent="handleSubmit"
     * >
     *     ...
     *     <isprint value="${
     *         formElement(pdict.trackOrderForm.googleRecaptcha)
     *         .setData('widget.attributes.data-site-key', googleRecaptchaHelper.getCaptchaKey())
     *         .setData('widget.attributes.data-recaptcha-enabled', googleRecaptchaHelper.isEnabled())
     *         .setData('widget.attributes.data-recaptcha-error-message', Resource.msg('form.recaptcha.error', 'forms', null))
     *         .render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     */
    class GoogleRecaptcha extends InputHidden {
        prefs() {
            return {
                siteKey: '',
                recaptchaErrorMessage: '',
                recaptchaEnabled: false,
                recaptchaAction: '',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
        }

        /**
         * @description Google Captcha success executing handler
         * @param token Google Captcha token
         */
        onRecaptchaSuccess(token: string) {
            this.ref('field').val(token);
        }

        /**
         * @description Google Captcha errors handler
         */
        onRecaptchaError() {
            this.emitWithoutContext('captchainiterror', {
                message: this.prefs().recaptchaErrorMessage
            });
        }

        /**
         * @description Google Captcha expiration handler
         */
        onRecaptchaReset() {
            this.ref('field').val('');
        }

        /**
         * @description Execute Google Recaptcha and returns promise
         * @returns Promise
         */
        executeRecaptcha(): Promise<string|null> {
            const action = this.prefs().recaptchaAction;
            const siteKey = this.prefs().siteKey;

            if (!window?.grecaptcha?.enterprise || !action) {
                this.onRecaptchaError();

                return Promise.resolve(null);
            }

            return new Promise((resolve) => {
                window.grecaptcha
                    .enterprise
                    .execute(siteKey, { action })
                    .then(
                        (token) => {
                            if (typeof token === 'string') {
                                this.onRecaptchaSuccess(token);
                                resolve(token);
                            } else {
                                this.onRecaptchaError();
                                resolve(null);
                            }
                        },
                        () => {
                            this.onRecaptchaError();
                            resolve(null);
                        }
                    );
            });
        }

        /**
         * @description Reloaded method to disable scrolling on validation fail
         */
        setFocus() {
            // Override setFocus logic
        }
    }

    return GoogleRecaptcha;
}

export type TGoogleRecaptcha = ReturnType<typeof GoogleRecaptchaClassCreator>;

export type TGoogleRecaptchaInstance = InstanceType<TGoogleRecaptcha>;

export default GoogleRecaptchaClassCreator;

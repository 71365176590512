/* eslint-disable indent */
const hasContext = (context: string) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('global'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'gigya.global' */'./widgets/global/HandleCustomerAccount').then(d => d.default)
    ]).then(deps => {
        const [
            HandleCustomerAccount
        ] = deps;

        return {
            listId: 'gigya.global',
            widgetsDefinition: () => [
                ['handleCustomerAccount', HandleCustomerAccount]
            ]
        };
    })
});

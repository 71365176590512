/* eslint-disable indent */

const hasContext = (ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';
import CarouselExt from './widgets/pagedesigner/CarouselExt';
import VideoExt from './widgets/pagedesigner/VideoExt';
import AnimationOnScroll from './widgets/pagedesigner/AnimationOnScroll';
import CtaExt from './widgets/pagedesigner/CtaExt';
import HotSpot from './widgets/pagedesigner/HotSpot';
import CarouselSimple from './widgets/pagedesigner/CarouselSimple';
import ProgressBar from './widgets/pagedesigner/ProgressBar';
import BannerCta from './widgets/pagedesigner/BannerCta';

widgetsMgr.addWidgetsList('page_designer.global.core', () => [
    ['carouselExt', CarouselExt, 'carousel'],
    ['videoExt', VideoExt],
    ['animationOnScroll', AnimationOnScroll],
    ['ctaExt', CtaExt],
    ['hotSpot', HotSpot],
    ['carouselSimple', CarouselSimple, 'carousel'],
    ['progressBar', ProgressBar],
    ['bannerCta', BannerCta]
]);

// widgetsMgr.asyncLists.push({
//     getLoadingFlag: () => hasContext('page_designer'),
//     load: () => Promise.all([
//         import(/* webpackChunkName: 'extended.page_designer' */ './widgets/pagedesigner/YourWidget').then(d => d.default)
//     ]).then(deps => {
//         const [YourWidget] = deps;

//         return {
//             listId: 'extended.page_designer',
//             widgetsDefinition: () => [
//                 ['yourWidget', YourWidget]
//             ]
//         };
//     })
// });

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('page_designer'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'extended.page_designer' */ 'widgets/global/HeroCarousel').then(d => d.default),
        import(/* webpackChunkName: 'extended.page_designer' */ 'widgets/search/NoSearchResultsForm').then(d => d.default)
    ]).then(deps => {
        const [
            HeroCarousel,
            NoSearchResultsForm
        ] = deps;

        return {
            listId: 'extended.page_designer',
            widgetsDefinition: () => [
                ['heroCarousel', HeroCarousel],
                ['noSearchResultsForm', NoSearchResultsForm, 'basicForm']
            ]
        };
    })
});

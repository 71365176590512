import WidgetsMgr from 'widgets/widgetsMgr';
import 'widgets/widgetsList';

/*
Don't delete next comment, it's a placeholder for additional imports
See details in documentation/tools/WebpackConfiguration.md
 */
import 'plugin_storelocator/widgetsList.ts';
import 'int_cybersource_sfra_w/cybersourceWidgetsList.ts';
import 'plugin_applepay/applepayWidgetsList.ts';
import 'plugin_productcompare/widgetsList.ts';
import 'plugin_giftcertificates/giftCertificateWidgetsList.ts';
import 'plugin_wishlists_w/wishlistWidgetsList.ts';
import 'plugin_serviceworker/sw-registration.ts';
import 'plugin_googlerecaptcha/widgetsList.js';
import 'int_gigya_w/widgetsList.ts';
import 'int_turnto_custom/widgetsList.ts';
import 'int_address_verification/uspsWidgetsList.ts';
import 'plugin_page_designer/pageDesignerWidgetsList.ts';
import 'plugin_page_designer_ext/pagedesignerWidgetList.ts';
import 'app_koc/kocWidgetsList.ts';
import 'int_adobe_analytics/clientAdobe.ts';
import 'int_firstdata/firstDataWidgetsList.ts';
import 'core/widgetsList';
import 'hallmark/widgetsList';

WidgetsMgr.run();

if (module.hot) {
    module.hot.accept([
        'widgets/widgetsList',
        'core/widgetsList',
        'hallmark/widgetsList'
    ], () => WidgetsMgr.restartWidgets());
}

/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */

const hasContexts = (/** @type {Array<string>} */contexts) => contexts.every(context => window.contexts.includes(context));

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContexts(['checkout', 'cybersource']),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/CyberSourceFlexMicroform').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/PaymentAccordionItemCybersourceGooglePay').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/PaymentAccordionItemCREDIT_CARD_SA_FLEX').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/paymentAccordionItemCREDIT_CARD_SA_IFRAME').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/CybersourceCheckoutMgr').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/checkout/PayerAuth').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/common/CybersourceGooglePayButton').then(d => d.default)
    ]).then(deps => {
        const [
            CyberSourceFlexMicroform,
            PaymentAccordionItemCybersourceGooglePay,
            PaymentAccordionItemCreditCardSaFlex,
            PaymentAccordionItemCreditCardSaIframe,
            CybersourceCheckoutMgr,
            PayerAuth,
            GooglePayButton
        ] = deps;

        return {
            listId: 'checkout.cybersource',
            widgetsDefinition: () => [
                ['cybersourceFlexMicroform', CyberSourceFlexMicroform, 'basicInput'],
                ['paymentAccordionItemCybersourceGooglePay', PaymentAccordionItemCybersourceGooglePay, 'paymentAccordionItem'],
                ['paymentAccordionItemCREDIT_CARD_SA_FLEX', PaymentAccordionItemCreditCardSaFlex, 'paymentAccordionItemCREDIT_CARD'],
                ['paymentAccordionItemCREDIT_CARD_SA_IFRAME', PaymentAccordionItemCreditCardSaIframe, 'paymentAccordionItemCREDIT_CARD'],
                ['checkoutMgr', CybersourceCheckoutMgr, 'checkoutMgr'],
                ['payerAuth', PayerAuth],
                ['cybersourceGooglePayButton', GooglePayButton]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContexts(['account', 'cybersource']),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddPaymentMethodForm').then(d => d.default),
        import(/* webpackChunkName: 'account.cybersource' */ 'widgets/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'account.cybersource' */ 'widgets/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ 'hallmark/forms/SmartyAutocompleteField').then(d => d.default)
    ]).then(deps => {
        const [
            AddPaymentMethodForm,
            AddressForm,
            SmartyAutocompleteField
        ] = deps;

        return {
            listId: 'account.payment.cybersource',
            widgetsDefinition: () => [
                ['cybersourceAddPaymentMethodForm', AddPaymentMethodForm, 'addPaymentMethodForm'],
                ['cybersourceAddPaymentMethodForm', AddressForm, 'cybersourceAddPaymentMethodForm'],
                ['smartyAutocompleteField', SmartyAutocompleteField, 'autocompleteField']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContexts(['cart', 'cybersource']),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.cybersource.paypal' */ './widgets/common/CybersourcePayPalBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.cybersource.paypal' */ './widgets/common/PayPalProcessPlaceOrderMixin').then(d => d.default),
        import(/* webpackChunkName: 'cart.cybersource.paypal' */ './widgets/cart/CybersourcePayPalCartButton').then(d => d.default),
        import(/* webpackChunkName: 'cart.cybersource.paypal' */ './widgets/cart/CybersourcePayPalCartStaticButton').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource' */ './widgets/common/CybersourceGooglePayButton').then(d => d.default)
    ]).then(deps => {
        const [
            PayPalBaseButton,
            PayPalProcessPlaceOrderMixin,
            PayPalCartButton,
            PayPalCartStaticButton,
            GooglePayButton
        ] = deps;

        return {
            listId: 'cart.cybersource.paypal',
            widgetsDefinition: () => [
                ['cybersourcePayPalCartButton', PayPalBaseButton],
                    ['cybersourcePayPalCartButton', PayPalProcessPlaceOrderMixin, 'cybersourcePayPalCartButton'],
                    ['cybersourcePayPalCartButton', PayPalCartButton, 'cybersourcePayPalCartButton'],
                ['cybersourcePayPalCartStaticButton', PayPalCartStaticButton],
                    ['cybersourcePayPalCartStaticButton', PayPalProcessPlaceOrderMixin, 'cybersourcePayPalCartStaticButton'],
                ['cybersourceGooglePayButton', GooglePayButton]
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContexts(['checkout', 'cybersource']),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.cybersource.paypal' */ './widgets/checkout/PaymentAccordionItemCybersourcePayPal').then(d => d.default),
        import(/* webpackChunkName: 'cart.cybersource.paypal' */ './widgets/common/PayPalProcessPlaceOrderMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource.paypal' */ './widgets/common/CybersourcePayPalBaseButton').then(d => d.default),
        import(/* webpackChunkName: 'checkout.cybersource.paypal' */ './widgets/billing/CybersourcePayPalBillingButton').then(d => d.default)
    ]).then(deps => {
        const [
            PaymentAccordionItemCybersourcePayPal,
            PayPalProcessPlaceOrderMixin,
            CybersourcePayPalBaseButton,
            CybersourcePayPalBillingButton,
        ] = deps;

        return {
            listId: 'checkout.cybersource.paypal',
            widgetsDefinition: () => [
                ['paymentAccordionItemCybersourcePayPal', PaymentAccordionItemCybersourcePayPal, 'paymentAccordionItem'],
                ['cybersourcePayPalBillingButton', CybersourcePayPalBaseButton],
                    ['cybersourcePayPalBillingButton', PayPalProcessPlaceOrderMixin, 'cybersourcePayPalBillingButton'],
                    ['cybersourcePayPalBillingButton', CybersourcePayPalBillingButton, 'cybersourcePayPalBillingButton']
            ]
        };
    })
});

/* eslint-disable max-len */
/* eslint-disable indent */
const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('page_designer'),

    load: () => Promise.all([
        import(/* webpackChunkName: 'global.page_designer' */'./widgets/page_designer/EinsteinCarouselLoader').then(d => d.default)
    ]).then(deps => {
        const [EinsteinCarouselLoader] = deps;

        return {
            listId: 'global.page_designer',
            widgetsDefinition: () => [
                ['einsteinCarouselLoader', EinsteinCarouselLoader]
            ]
        };
    })
});

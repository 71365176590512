import { RefElement } from 'widgets/toolbox/RefElement';
import { TWidget } from 'widgets/Widget';
import { scrollIntoView } from 'widgets/toolbox/scroll';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
});

const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const ARIA_DISABLED = 'aria-disabled';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base AccordionItem implementation
 * @param Widget base widget class
 * @returns AccordionItem widget
 */
function AccordionItemClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccordionItem
     * @augments Widget
     * @classdesc Represents AccordionItem with specific logic for accordion item, handling viewtype changes, keyboard navigation
     * Represents AccordionItem component with next features:
     * 1. Configurable via data attribute epanded state
     * 2. Support keyboard navigation for accessibility
     * 3. Define accessibility
     * @property {string} data-widget - Widget name `accordionItem`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {string} data-event-click - Event listener for `togglePanel` method
     * @property {string} data-widget-event-accordionitemupdate - method to be triggered on parent's widget to update current accordion item
     * @property {boolean} [data-expanded=false] - Expanded flag
     * @example
     * // use this code to display widget
     *   <section
     *      class="b-product_accordion-item"
     *      data-widget="accordionItem"
     *      data-widget-event-closeallitems="closeItems"
     *      data-widget-event-accordionitemupdate="updateFocusedItem"
     *  >
     *      <button
     *          class="b-refinements_accordion-button"
     *          data-ref="accordionItemBtn"
     *          data-event-click.prevent="togglePanel"
     *          data-event-keydown="handleKeydown"
     *          data-event-focus="handleFocus"
     *          type="button"
     *      >
     *          <span class="b-refinements_accordion-title">Account</span>
     *          <span class="b-icon_chevron"></span>
     *      </button>
     *      <div data-ref="accordionItemPanel">
     *          <div data-ref="accordionItemPanelInner">
     *              ... account panel content
     *          </div>
     *      </div>
     *  </section>
     */

    class AccordionItem extends Widget {
        /**
         * @description Is current panel opened
         */
        isPanelOpen = false;

        /**
         * @description Is panel toggle allowed
         */
        isToggleAllowed = false;

        /**
         * @description Is Multiple section are allowed to be opened in same time
         */
        isMultipleSections = false;

        /**
         * @description Returns Widget configuration object
         * @returns Widget configuration object
         */
        prefs() {
            return {
                accordionItemBtn: 'accordionItemBtn',
                accordionItemPanel: 'accordionItemPanel',
                accordionItemPanelInner: 'accordionItemPanelInner',
                expanded: false,
                animateToggle: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            this.isPanelOpen = this.prefs().expanded;
            this.isToggleAllowed = false;
            this.isMultipleSections = false;
            this.defineAttributes(this.prefs().expanded);
            this.onDestroy(this.clearAttributes.bind(this));
            this.onDestroy(this.cleanPanelHeight.bind(this));

            if (!this.isPanelOpen) {
                this.closePanel(true);
            }
        }

        /**
         * @description Define attributes
         * @param [isOpen] Panel open flag
         */
        defineAttributes(isOpen?: boolean) {
            const accordionItemBtn = this.ref('accordionItemBtn');
            const accordionItemPanel = this.ref('accordionItemPanel');
            const accordionItemBtnID = accordionItemBtn.attr('id');
            const accordionItemPanelID = accordionItemPanel.attr('id');

            accordionItemBtn.attr('role', 'button');
            accordionItemBtn.attr('aria-controls', accordionItemPanelID);
            accordionItemBtn.attr(ARIA_EXPANDED, 'false');
            accordionItemBtn.attr('tabindex', '0');
            accordionItemPanel.attr('role', 'region');
            accordionItemPanel.attr('aria-labelledby', accordionItemBtnID);

            if (isOpen) {
                accordionItemPanel.attr(ARIA_HIDDEN, 'false');
                accordionItemBtn.attr(ARIA_EXPANDED, 'true');
            } else {
                accordionItemPanel.attr(ARIA_HIDDEN, 'true');
                accordionItemBtn.attr(ARIA_EXPANDED, 'false');
            }
        }

        /**
         * @description Clear Attributes
         */
        clearAttributes() {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr('role', false);
                accordionItemBtn.attr('aria-controls', false);
                accordionItemBtn.attr(ARIA_EXPANDED, false);
                accordionItemBtn.attr(ARIA_DISABLED, false);
                accordionItemBtn.attr('tabindex', false);
            });
            this.has(this.prefs().accordionItemPanel, (accordionItemPanel) => {
                accordionItemPanel.attr('role', false);
                accordionItemPanel.attr('aria-labelledby', false);
                accordionItemPanel.attr(ARIA_HIDDEN, false);
            });
        }

        /**
         * @description Set Focus
         */
        focus() {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.focus();
            });
        }

        /**
         * @description On Refresh Handler
         */
        onRefresh() {
            super.onRefresh();
            this.defineAttributes(this.isPanelOpen);

            if (!this.isPanelOpen) {
                this.closePanel();
            }
        }

        /**
         * @description Open panel
         * @emits AccordionItem#closeallitems
         * @emits AccordionItem#openpanel
         */
        openPanel() {
            if (!this.isMultipleSections) {
                /**
                 * @description Event to close all items
                 * @event AccordionItem#closeallitems
                 */
                this.emit('closeallitems');
            }

            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr(ARIA_EXPANDED, 'true');

                if (!this.isToggleAllowed) {
                    accordionItemBtn.attr(ARIA_DISABLED, 'true');
                }
            });
            this.has(
                this.prefs().accordionItemPanel,
                accordionItemPanel => this.togglePanelHeight(true, accordionItemPanel)
            );

            const panelElement = this.ref('accordionItemPanel').get();

            setTimeout(() => {
                if (!this.isElementInView(panelElement)) {
                    scrollIntoView(panelElement);
                }
            }, 250);

            this.isPanelOpen = true;
            /**
             * @description Event for opening panel
             * @event AccordionItem#openpanel
             */
            this.emit('openpanel');
        }

        /**
         * @description Close panel
         * @param skipEvent Skip event marker (could be used in child classes)
         */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        closePanel(skipEvent?: boolean) {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr(ARIA_EXPANDED, 'false');

                if (!this.isToggleAllowed) {
                    accordionItemBtn.attr(ARIA_DISABLED, false);
                }
            });
            this.has(
                this.prefs().accordionItemPanel,
                accordionItemPanel => this.togglePanelHeight(false, accordionItemPanel)
            );

            this.isPanelOpen = false;
        }

        /**
         * @description Toggle panel
         */
        togglePanel() {
            if (!this.isPanelOpen) {
                this.openPanel();
            } else if (this.isToggleAllowed) {
                this.closePanel();
            }
        }

        /**
         * @description Toggle panel height, so it could be properly animated.
         * It is required inner element to has content height properly.
         * @param isOpen Panel Open flag
         * @param panel IsOpen flag
         */
        togglePanelHeight(isOpen: boolean, panel: RefElement) {
            panel.attr(ARIA_HIDDEN, isOpen ? 'false' : 'true');

            this.has(this.prefs().accordionItemPanelInner, inner => {
                const panelElement = panel.get();
                const innerElement = inner.get();

                if (innerElement && panelElement) {
                    panelElement.style.height = `${isOpen ? innerElement.offsetHeight : 0}px`;
                }
            });
        }

        /**
         * @description Remove hard-coded panel height in case of widget destroyed
         */
        cleanPanelHeight() {
            this.has(this.prefs().accordionItemPanel, accordionItemPanel => {
                const element = accordionItemPanel.get();

                if (element) {
                    element.style.height = 'auto';
                }
            });
        }

        /**
         * @description Update accordion item state, recalculate item height
         * TODO: Describe approach for accordion height in case of content change
         */
        updateState() {
            this.has(
                this.prefs().accordionItemPanel,
                accordionItemPanel => {
                    this.togglePanelHeight(Boolean(this.isPanelOpen), accordionItemPanel);
                }
            );
        }

        /**
         * @description Focus Event handler
         * @emits AccordionItem#accordionitemupdate
         */
        handleFocus() {
            /**
             * @description Event to set focused Accordion item as current
             * @event AccordionItem#accordionitemupdate
             */
            this.emit('accordionitemupdate', 'current');
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param _ Source of keydown event
         * @param event  Event object
         */
        handleKeydown(_: HTMLElement, event: KeyboardEvent) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.togglePanel();
                    preventEventActions = true;

                    break;

                case keyCode.HOME:
                    /**
                     * @description Event to set first Accordion item as current
                     * @event AccordionItem#accordionitemupdate
                     */
                    this.emit('accordionitemupdate', 'first');
                    preventEventActions = true;

                    break;

                case keyCode.END:
                    /**
                     * @description Event to set last Accordion item as current
                     * @event AccordionItem#accordionitemupdate
                     */
                    this.emit('accordionitemupdate', 'last');
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    /**
                     * @description Event to set previous Accordion item as current
                     * @event AccordionItem#accordionitemupdate
                     */
                    this.emit('accordionitemupdate', 'previous');
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    /**
                     * @description Event to set next Accordion item as current
                     * @event AccordionItem#accordionitemupdate
                     */
                    this.emit('accordionitemupdate', 'next');
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Hide description
         * @listens initerrorhandler
         */
        hideDescription() {
            this.ref('accordionItemPanelDescription').hide();
        }

        /**
         * @description Check if element fully visible
         * @param el HTML eleent
         */
        isElementInView(el) {
            const rect = el.getBoundingClientRect();

            // Only completely visible elements return true:
            const isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight);

            return isVisible;
        }
    }

    return AccordionItem;
}

export type TAccordionItem = ReturnType<typeof AccordionItemClassCreator>;

export type TAccordionItemInstance = InstanceType<TAccordionItem>;

export default AccordionItemClassCreator;

import { TBasicInputInstance } from 'widgets/forms/BasicInput';

/**
 * @param BaseEmailSubscribe Base widget for extending
 * @returns EmailSubscribe widget
 */
function EmailSubscribeClassCreator(BaseEmailSubscribe: import('widgets/forms/ajax/EmailSubscribe').TEmailSubscribe) {
    /**
     * @category widgets
     * @subcategory forms/ajax
     * @class EmailSubscribe
     * @augments BaseEmailSubscribe
     * @classdesc Serves email subscription form in footer.
     * The main purpose EmailSubscribe widget is to allow send/validate the request to server and handles server response
     * @property {string} data-widget - Widget name `emailSubscribe`
     * @property {string} data-event-submit - Event listener for form submission
     *
     */
    class EmailSubscribe extends BaseEmailSubscribe {
        /**
         * @description Handles server response
         * @emits "alert.show"
         * @param data Server JSON response once form submitted
         */
        onSubmitted(data: TServerResponse) {
            if (data.success) {
                this.ref('successMessage')
                    .setText(<string>data.msg)
                    .show();
            }

            if (!data.success) {
                if (data.fieldErrors) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMsg]) => {
                        this.getById(name, (formField: TBasicInputInstance) => formField.setError(errorMsg));
                    });
                }

                if (data.errorMessage) {
                    // Show only Accessibility Alert in case of 500 error
                    if (data.errorCode !== 500) {
                        this.getById(this.prefs().signupEmail, (inputEmail: TBasicInputInstance) => inputEmail.setError(data.errorMessage));
                    }
                }
            }
        }
    }

    return EmailSubscribe;
}

export type TEmailSubscribe = ReturnType<typeof EmailSubscribeClassCreator>;

export type TEmailSubscribeInstance = InstanceType<TEmailSubscribe>;

export default EmailSubscribeClassCreator;

import { initAdobeEvents } from './components/events';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */
/**
 * @description clientAdobe
 * @module clientAdobe
 * @category widgets
 * @subcategory int_adobe_analytics
 */

/**
 * @description Init Adobe callback
 */
function init() {
    initAdobeEvents();
}

if (window.dataLayer) {
    if (document.readyState === 'loading') {
        /**
         * @description Init gtm once dom content loaded
         * @listens dom:DOMContentLoaded
         */
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                init();
            }, 0);
        }, { once: true });
    } else {
        init();
    }
}

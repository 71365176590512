import WidgetsMgr from 'widgets/widgetsMgr';

const hasContext = (context) => window.contexts.includes(context);

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.egiftcertificate.widgets' */ './widgets/checkout/PaymentMethodHmkGiftCard')
            .then(d => d.default)
    ]).then(deps => {
        const [PaymentMethodHmkGiftCard] = deps;

        return {
            listId: 'checkout.egiftcertificate.widgets',
            widgetsDefinition: () => [
                ['paymentMethodHmkGiftCard', PaymentMethodHmkGiftCard]
            ]
        };
    })
});

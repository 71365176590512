import { timeout } from '../toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';

import { TWidget } from 'widgets/Widget';
import { TRefElementInstance } from 'widgets/toolbox/RefElement';
import { TButtonInstance } from 'widgets/global/Button';
import { TBasicInput, TBasicInputInstance } from 'widgets/forms/BasicInput';

/**
 * @param Widget base widget class
 * @returns Basic Form class
 */
function BasicFormClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BasicForm
     * @augments Widget
     * @classdesc Represents BasicForm component with next features:
     * 1. Allow show/hide Form progress bar
     * 2. Allow get submit button name, Form fields, Form action url
     * 3. Allow submit and handle submit Form
     * 4. Allow set value to Form fields
     * 5. Allow validate Form and Form fields
     * 6. Allow updates Form html body by sending an AJAX request to server with params object
     * BasicForm widget should contain {@link Button} widgets that implement submit Form button.
     * Widget has next relationship:
     * * Handle Form submit using method {@link BasicForm#handleSubmit} by event {@link Button#event:click} from {@link Button} widget.
     * @example <caption>Example of BasicForm widget usage when Submit Button have assigned widget</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
     *       data-widget="button"
     *       data-id="submitButton"
     *       type="submit"
     *       data-forward-to-parent="handleSubmit"
     *       data-event-click="handleSubmit"
     *    >
     *       ...Button name
     *    </button>
     * </form>
     * @example <caption>Example of BasicForm widget usage when submit button NOT have assigned widget</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
     *       data-ref="submitButton"
     *       type="submit"
     *       data-event-click="handleSubmit"
     *    >
     *       ...Button name
     *    </button>
     * </form>
     * @property {string} data-widget - Widget name `form`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class BasicForm extends Widget {
        submitting?: boolean;

        prefs() {
            return {
                submitEmpty: true,
                emptyFormErrorMsg: '',
                submitButton: 'submitButton',
                errorMessageLabel: 'errorMessageLabel',
                SUBMITTING_TIMEOUT: 5000,
                formDefinitionUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Set aria-busy attribute value true
         */
        busy() {
            this.ref('self').attr('aria-busy', 'true');
        }

        /**
         * @description Set aria-busy attribute value false
         */
        unbusy() {
            this.ref('self').attr('aria-busy', 'false');
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();

            this.addAutofillListener();

            this.eachField(widget => {
                /**
                 * Below code is needed to initiate correct comparison configuration for certain fields
                 * It iterates through all BasicInput children and if it finds the compare data in validation -
                 * it will set necessary data to a proper child BasicInput widget
                */

                const widgetValidation = widget.prefs().validationConfig || {};

                if (!Array.isArray(widgetValidation.compareWith)) { return; }

                widgetValidation.compareWith.forEach((compareFieldItem, index) => {
                    this.getById(compareFieldItem.field, targetWidget => {
                        if (targetWidget.length) {
                            const compareOptions = {
                                field: compareFieldItem.field,
                                msg: widgetValidation.errors.compareWith[index] || '',
                                equality: compareFieldItem.equality
                            };

                            widget.data('setMatchCmp', targetWidget, compareOptions);
                        }
                    });
                });
            });
        }

        /**
         * @description Process children fields with callback passed as param
         * @param fn - callback function
         * @returns List of fields processing result
         */
        eachField(fn: (n: TBasicInputInstance) => unknown): Array<unknown> {
            return this.getFields().map(item => {
                return fn(item);
            });
        }

        /**
         * @description Get form field instances
         */
        getFields(): Array<TBasicInputInstance> {
            const BasicInput = <TBasicInput> this.getConstructor('basicInput');
            const items = this.items ? this.items : [];

            return items.filter((item: unknown): item is TBasicInputInstance => item instanceof BasicInput);
        }

        /**
         * @description Submit Form
         */
        submit() {
            const elem = this.ref(this.prefs().submitButton).get();

            if (elem) {
                elem.click();
            }
        }

        /**
         * @description Handle submit Form
         * @emits BasicForm#submit
         * @param _el event source element
         * @param eventOrButton event instance if DOM event and button instance if widget event
         */
        handleSubmit(_el: TRefElementInstance, eventOrButton?: Event | TRefElementInstance) {
            this.clearError();

            const valid = this.isChildrenValid();

            if ((!valid || this.submitting) && (eventOrButton && eventOrButton instanceof Event)) {
                eventOrButton.preventDefault();

                return;
            }

            if (eventOrButton && eventOrButton instanceof SubmitEvent) {
                this.has(this.prefs().submitButton, (submitButton) => {
                    submitButton.disable();
                });
                this.getById<TButtonInstance>(this.prefs().submitButton, (submitButton) => {
                    submitButton.disable();
                });

                this.submitting = true;
                this.onDestroy(timeout(() => {
                    this.submitting = false;
                }, this.prefs().SUBMITTING_TIMEOUT));
                this.emit('submit');
            }
        }

        /**
         * @description Get Form fields
         * @returns Object contains form fields
         */
        getFormFields(): {[key: string]: string | undefined} {
            const fields = {};

            this.eachField(widget => {
                if (!(widget.isSkipSubmission && widget.isSkipSubmission())) {
                    const name = widget.getName();

                    if (name) {
                        fields[name.toString()] = widget.getValue();
                    }
                }
            });

            return fields;
        }

        /**
         * @description Sets related fields values, can be executed silently, without triggering `change` event
         * @param formFields - Structured object with name: value pairs for input fields
         * @param silently - if set to `true` - input should not be validated against a new value
         */
        setFormFields(formFields: Record<string, string>, silently = false) {
            this.eachField(widget => {
                const name = widget.getName();

                widget.setValue(formFields[name], silently);
            });
        }

        /**
         * @description Sets value for particular field
         * @param name - field name
         * @param value - value to set
         * @param silently - input should not be validated against a new value and no events should be fired
         * @param throwErrorOnMissingField - sets behavior if field with given name is not found
         */
        setFormFieldValue(name: string, value: string, silently = false, throwErrorOnMissingField = false) {
            const fields = this.getFields();

            const field = fields.find(f => f.getName() === name);

            if (!field) {
                if (throwErrorOnMissingField) {
                    throw new Error(`Field with name ${name} not found`);
                }

                return;
            }

            field.setValue(value, silently);
        }

        /**
         * @description Check is Form fields valid
         * @param cb callback called if child inputs are valid
         * @returns boolean value is Form input valid
         */
        isChildrenValid(cb?: () => void): boolean {
            let valid = true;

            this.eachField(item => {
                if (typeof item.validate === 'function' && !item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }

                    valid = false;
                }
            });

            if (valid && typeof cb === 'function') {
                cb();
            }

            if (!this.prefs().submitEmpty) {
                const fieldsValues = this.getFormFields();

                if (Object.keys(fieldsValues).every((key) => !fieldsValues[key])) {
                    valid = false;
                    this.ref(this.prefs().errorMessageLabel)
                        .setText(this.prefs().emptyFormErrorMsg);
                    this.ref(this.prefs().errorMessageLabel).show();
                }
            }

            return valid;
        }

        /**
         * @description Form validate
         * @returns boolean value is Form input valid
         */
        validate(): boolean {
            return this.isChildrenValid();
        }

        /**
         * @description Check is Form valid based on Form fields validation
         * @returns boolean value is Form valid
         */
        isValid(): boolean {
            let valid = true;

            this.eachField((itemComponent) => {
                if (typeof itemComponent.isValid === 'function'
                    && !itemComponent.isValid()
                ) {
                    valid = false;

                    return false;
                }

                return true;
            });

            return valid;
        }

        /**
         * @description Interface that can be override
         */
        setFocus() {
            throw Error('setFocus method not implemented');
        }

        /**
         * @description Get Form action url
         * @returns form action url
         */
        getFormUrl(): string {
            const formURL = this.ref('self').attr('action');

            return formURL.toString();
        }

        /**
         * @description Clear Form Error
         * @param refID RefElement ID
         */
        clearError(refID = 'errorMessageLabel') {
            this.ref(refID)
                .hide()
                .setText('');
        }

        /**
         * @description Updates form html body by sending an AJAX request to server with params object
         * <br>(possible param key is `countryCode`)
         * <br>Obtained template injected instead of old fields
         * <br>Data, entered in fields previously will be restored
         * @param params - request parameters
         * @param params.countryCode - A country code to get country-specific form
         * @returns Promise object represents rendering result
         */
        updateFormData(params: Record<string, string>): Promise<boolean> {
            const formDefinitionUrl = this.prefs().formDefinitionUrl;

            if (formDefinitionUrl && params) {
                return new Promise((resolve, reject) => {
                    getJSONByUrl(formDefinitionUrl, params, true).then((response) => {
                        if (response.formDefinition) {
                            const formFields = this.getFormFields();

                            this.render('', {}, this.ref('fieldset'), <string> response.formDefinition).then(() => {
                                Object.entries(formFields).forEach(([fieldName, fieldValue]) => {
                                    this.getById(fieldName, (field: TBasicInputInstance) => {
                                        field.setValue(fieldValue, true);
                                    });
                                });
                                resolve(true);
                                setTimeout(() => this.formDataUpdated(), 0);
                            }).catch(() => {
                                reject(new Error('Form definition rendering error'));
                            });
                        } else {
                            reject(new Error('Form definition is empty'));
                        }
                    });
                });
            }

            return new Promise((resolve) => {
                resolve(true);
                this.formDataUpdated();
            });
        }

        /**
         * @description Template method called once form definitions were reloaded
         */
        formDataUpdated() {
            // Placeholder for additional logic on form data updating
        }

        /**
         * @description add form elements autofill listeners. Appropirate animation
         * fires when suggested values are filled into the form.
         */
        addAutofillListener() {
            this.ev('animationstart', (element, event: AnimationEvent) => {
                switch (event.animationName) {
                    case 'autofill-start':
                        this.handleAutofillStart();
                        break;
                    case 'autofill-end':
                        this.handleAutofillEnd();
                        break;
                    default:
                        break;
                }
            }, this.ref('self').get(0));
        }

        /**
         * @description Handles an event of filling the form by previously entered information
         * Note: this event fires when suggested values are filled into the form as a preview,
         * if you want to handle event when user actually selects some of suggested addresses - you should look for different event.
         */
        handleAutofillStart() {
            // Placeholder
        }

        /**
         * @description Handles an event of leaving browser suggestion area without selecting
         */
        handleAutofillEnd() {
            // Placeholder
        }
    }

    return BasicForm;
}

export type TBasicForm = ReturnType<typeof BasicFormClassCreator>;

export type TBasicFormInstance = InstanceType<TBasicForm>;

export default BasicFormClassCreator;

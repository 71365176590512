import { TWidget } from 'widgets/Widget';

/**
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base BannerCta implementation
 * @param Widget Base widget for extending
 * @returns BannerCta widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class BannerCta
     * @augments Widget
     * @property {string} data-widget - Widget name `BannerCta`
     */

    class BannerCta extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns Widget configuration object
         */
        prefs() {
            return {
                rows: 0,
                length: 0,
                ctaWrapper: 'ctaExtWrapper',
                ctaItemsClass: 'b-actions-items',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init(): void {
            this.setWrappers();
        }

        /**
         * @description Set expected wrapper for each element in the list
         */
        setWrappers() {
            if (this.prefs().rows || this.prefs().length) {
                const selfEl = this.ref('self')?.get() as HTMLElement;

                const selfHtml = this.ref('self').get() as HTMLElement;
                const children = Array.from(selfEl.children);

                // Calculate the number of children per new parent block
                const childrenPerBlock = Math.ceil(children.length / this.prefs().rows);

                // Loop through the child elements and create new parent blocks as needed
                for (let i = 0; i < children.length; i += childrenPerBlock) {
                    // Create a new parent block
                    const newParent = document.createElement('div');

                    newParent.classList.add(this.prefs().ctaItemsClass);

                    // Loop through the children for this block and append them to the new parent
                    for (let j = i; j < i + childrenPerBlock && j < children.length; j++) {
                        newParent.appendChild(children[j]);
                    }

                    // Append the new parent block to the parent
                    selfHtml.appendChild(newParent);
                }
            }
        }
    }

    return BannerCta;
}

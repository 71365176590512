/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */

const hasContext = (/** @type {string} */context) => window.contexts.includes(context);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account') && hasContext('usps-avs'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.usps.avs' */ './widgets/forms/UspsAddressBookForm').then(d => d.default),
        import(/* webpackChunkName: 'account.widgets' */ './widgets/forms/UspsAddPaymentMethodForm').then(d => d.default),
        import(/* webpackChunkName: 'account.usps.avs' */ './widgets/checkout/AddressVerificationMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.usps.avs' */ './widgets/forms/MatchedAddressesModal').then(d => d.default),
        import(/* webpackChunkName: 'account.usps.avs' */ 'widgets/checkout/RadioSelector').then(d => d.default)
    ]).then(deps => {
        const [
            UspsAddressBookForm,
            UspsAddPaymentMethodForm,
            AddressVerificationMixin,
            MatchedAddressesModal,
            RadioSelector
        ] = deps;

        return {
            listId: 'account.usps.avs',
            widgetsDefinition: () => [
                ['addressForm', AddressVerificationMixin, 'addressForm'],
                ['addressForm', UspsAddressBookForm, 'addressForm'],
                ['addPaymentMethodForm', AddressVerificationMixin, 'addPaymentMethodForm'],
                ['addPaymentMethodForm', UspsAddPaymentMethodForm, 'addPaymentMethodForm'],
                ['matchedAddressesModal', MatchedAddressesModal],
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout') && hasContext('usps-avs'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.usps.avs' */ './widgets/checkout/UspsCheckoutMgr').then(d => d.default),
        import(/* webpackChunkName: 'checkout.usps.avs' */ './widgets/checkout/AddressVerificationMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.usps.avs' */ './widgets/checkout/UspsAddressCheckoutForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.usps.avs' */ './widgets/forms/MatchedAddressesModal').then(d => d.default)
    ]).then(deps => {
        const [
            UspsCheckoutMgr,
            AddressVerificationMixin,
            UspsAddressCheckoutForm,
            MatchedAddressesModal
        ] = deps;

        return {
            listId: 'checkout.usps.avs',
            widgetsDefinition: () => [
                ['checkoutMgr', UspsCheckoutMgr, 'checkoutMgr'],
                ['checkoutMgr', AddressVerificationMixin, 'checkoutMgr'],
                ['shippingForm', UspsAddressCheckoutForm, 'shippingForm'],
                ['billingForm', UspsAddressCheckoutForm, 'billingForm'],
                ['billingForm', AddressVerificationMixin, 'billingForm'],
                ['matchedAddressesModal', MatchedAddressesModal]
            ]
        };
    })
});

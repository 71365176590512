/**
 * @module wishlistItemsMgr
 * @category widgets
 * @subcategory plugin_wishlist
 * @description Represents wishlistItemsMgr component with next features:
 * 1. Allow getting items in the wish list
 * 2. Allow setting items from the wish list to the local storage
 * @example <caption>Example of WishlistItemsMgr module usage</caption>
 * import { WishlistItemsMgr } from 'widgets/toolbox/WishlistItemsMgr';
 *
 *  init() {
 *      super.init();
 *      wishlistItemsMgr.isItemInWishlist(this.prefs().pid, this.prefs().productOptions)
 *          .then((state) => {
 *              this.changeStateWishlistButton(state);
 *          })
 *      .catch(error => console.log(error));
 *  }
 */
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { getCookie } from 'widgets/toolbox/cookie';
import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';
type TWishlistItem = {
    id: string;
    optionId: string;
    selectedValueId: string;
};
type TResponse = {
    wishlistItems: Array<TWishlistItem>;
    isKeepsake: boolean;
};
declare global {
    interface ExtendedWindow extends Window {
        sfccData: Window['sfccData'] & {
            wishlistActionUrls: {
                getItems: string;
                addToWishlist: string;
                removeFromWishlist: string;
            };
        };
    }
}

declare const window: ExtendedWindow;

export class WishlistItemsMgr {
    // eslint-disable-next-line no-use-before-define
    static instance: WishlistItemsMgr | undefined;

    loadWishlistItemsPromise: Promise<TResponse> | undefined;
    loadKeepSakeWishlistItemsPromise: Promise<TResponse> | undefined;

    /**
     * @description Returns instance of WishlistItemsMgr
     * @returns Instance of WishlistItemsMgr
     */
    static getInstance(): WishlistItemsMgr {
        if (WishlistItemsMgr.instance) {
            return WishlistItemsMgr.instance;
        }

        WishlistItemsMgr.instance = new WishlistItemsMgr();

        return WishlistItemsMgr.instance;
    }

    /**
     * @description Check if product in wish list
     * @param productId Target product id
     * @param productOptions Product options
     * @param isKeepsake flag wich indicate KOC wishlist
     * @returns Result
     */
    isItemInWishlist(productId: string|number, productOptions?: Array<Record<string, unknown>>, isKeepsake?: boolean | undefined): Promise<boolean> {
        return this.getWishlistItems(isKeepsake)
            .then((response: TResponse) => {
                let result = false;

                if (!response) {
                    return Promise.resolve(result);
                }

                const items = response.wishlistItems;
                // TODO: This is a temporary solution.Implement support few options on product
                const option = productOptions ? productOptions[0] : null;
                const item = items.find(wishlistItem => wishlistItem.id === String(productId));

                if (!item) {
                    return Promise.resolve(result);
                }

                const itemOptionId = item.optionId;
                const selectedValueId = item.selectedValueId;

                result = true;

                if (option && itemOptionId && selectedValueId) {
                    result = option.id === itemOptionId && option.selectedValueId === selectedValueId;
                }

                return Promise.resolve(result);
            });
    }

    /**
     * @description Checks if wish list contains items
     * @returns Indicates if items in the wish list
     * @param isKeepsake flag wich indicate KOC wishlist
     */
    isItemsInWishList(isKeepsake?: boolean | undefined): Promise<boolean> {
        return this.getWishlistItems(isKeepsake)
            .then(result => Promise.resolve(result?.wishlistItems?.length > 0));
    }

    /**
     * @description Do call to get items in the wish list from the server
     * @param url URL to get items in the wish list
     * @param isKeepsake flag wich indicate KOC wishlist
     * @returns Fetching result promise
     */
    loadWishListItems(url: string, isKeepsake?: boolean | undefined): Promise<TResponse> {
        return getJSONByUrl(url, {
            isKeepsake: isKeepsake?.toString()
        })
            .then((result: TResponse) => {
                this.saveWishListItems(result.wishlistItems, result.isKeepsake);

                if (isKeepsake && !result.isKeepsake) {
                    localStorageWrapper.setItem('hideKeepsake', true);
                } else if (isKeepsake && result.isKeepsake) {
                    localStorageWrapper.setItem('hideKeepsake', false);
                }

                return Promise.resolve(result);
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error);

                return error;
            });
    }

    /**
    * @description Set wish list items to the local storage
    * @param wishlistItems Object contains items in the wish list
    * @param isKeepsake flag wich indicate KOC wishlist
    */
    saveWishListItems(wishlistItems: Array<TWishlistItem>, isKeepsake?: boolean | undefined) {
        const wishlistItemsKey = isKeepsake ? 'kepsakeWishlistItems' : 'wishlistItems';
        localStorageWrapper.setItem('currentSid', getCookie('sid'));
        localStorageWrapper.setItem(wishlistItemsKey, wishlistItems ? JSON.stringify(wishlistItems) : []);
    }

    /**
     * @description Update local storage with new items in the wish list
     * @param wishlistItems Object contains items in the wish list
     * @param isKeepsake flag wich indicate KOC wishlist
     */
    updateWishlistItems(wishlistItems: Array<TWishlistItem>, isKeepsake?: boolean | undefined) {
        localStorageWrapper.removeItem(isKeepsake ? 'kepsakeWishlistItems' : 'wishlistItems');
        this.saveWishListItems(wishlistItems, isKeepsake);
    }

    /**
     * Retrieves the wishlist items, either from local storage or by making a new API call if necessary.
     *
     * This method checks if a promise for fetching wishlist items (`loadWishlistItemsPromise`) already exists.
     * If it does, it returns the existing promise, preventing redundant API calls. If no such promise exists,
     * the method checks the session, site, and local storage to determine if it can use cached data. If cached
     * data is available and valid, it returns the cached data. Otherwise, it makes a new API call to retrieve
     * the wishlist items and stores the result in `loadWishlistItemsPromise` to avoid repeated requests.
     *
     * @param {boolean} [isKeepsake] - Optional flag indicating whether to retrieve items from the Keepsake wishlist.
     * @returns {Promise<TResponse>} A promise that resolves with the wishlist items and a flag indicating whether
     *                               the items belong to a Keepsake wishlist.
     */
    getWishlistItems(isKeepsake?: boolean): Promise<TResponse> {
        if (this.loadWishlistItemsPromise) {
            return this.loadWishlistItemsPromise;
        }

        const url = window.sfccData.wishlistActionUrls?.getItems || null;
        if (!url) {
            return Promise.resolve(null as unknown as TResponse);
        }

        const isSameSession = localStorageWrapper.getItem('currentSid') === getCookie('sid');
        const isSameSite = localStorageWrapper.getItem('currentSite') === window.sfccData.siteData.id;
        const isRegisteredCustomer = getCookie('accountType') === 'R';

        if (!isRegisteredCustomer) {
            localStorageWrapper.setItem('hideKeepsake', false);
        }

        const hideKeepsake = localStorageWrapper.getItem('hideKeepsake');

        if (hideKeepsake === null) {
            localStorageWrapper.removeItem('kepsakeWishlistItems');
            localStorageWrapper.removeItem('wishlistItems');
        }

        if (isRegisteredCustomer && isSameSession && isSameSite && hideKeepsake === 'true') {
            isKeepsake = false;
        }

        const wishlistItems = isKeepsake ? localStorageWrapper.getItem('kepsakeWishlistItems') : localStorageWrapper.getItem('wishlistItems');
        let items = JSON.parse(wishlistItems || 'null');

        if (isKeepsake && !isRegisteredCustomer) {
            items = [];
        }

        if (items && isSameSession && isSameSite) {
            return Promise.resolve({ wishlistItems: items, isKeepsake: !!isKeepsake });
        }

        this.loadWishlistItemsPromise = this.loadWishListItems(url, isKeepsake);
        return this.loadWishlistItemsPromise;
    }
}
WishlistItemsMgr.instance = undefined;

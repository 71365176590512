/* eslint-disable indent */
import widgetsMgr from 'widgets/widgetsMgr';

const hasContext = (context: string) => window.contexts.includes(context);

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('global'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'global.koc.widgets' */ 'koc/wishlist/AddToWishlistKOCExt').then(d => d.default),
        import(/* webpackChunkName: 'global.koc.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'global.koc.widgets' */ 'hallmark/forms/ajax/LoginFormMixin').then(d => d.default),
        import(/* webpackChunkName: 'global.koc.widgets' */ 'widgets/forms/AjaxFormResponseContainer').then(d => d.default),
        import(/* webpackChunkName: 'global.koc.widgets' */ 'widgets/forms/AjaxFormResponseContainerMigrated').then(d => d.default)
    ]).then(deps => {
        const [
            AddToWishlistKOCExt,
            LoginForm,
            LoginFormMixin,
            AjaxFormResponseContainer,
            AjaxFormResponseContainerMigrated
        ] = deps;

        return {
            listId: 'global.koc.widgets',
            widgetsDefinition: () => [
                ['productTile', AddToWishlistKOCExt, 'productTile'],
                ['carousel', AddToWishlistKOCExt, 'carousel'],
                ['loginForm', LoginForm, 'ajaxform'],
                ['loginFormMixin', LoginFormMixin, 'loginForm'],
                ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
                ['ajaxFormResponseContainerMigrated', AjaxFormResponseContainerMigrated, 'modal']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'pdp.koc.widgets' */ 'koc/wishlist/AddToWishlistKOCExt').then(d => d.default)
    ]).then(deps => {
        const [
            AddToWishlistKOCExt
        ] = deps;

        return {
            listId: 'pdp.koc.widgets',
            widgetsDefinition: () => [
                ['productDetail', AddToWishlistKOCExt, 'productDetail']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.koc.widgets' */ 'koc/forms/ajax/KocRegistrationForm').then(d => d.default)
    ]).then(deps => {
        const [
            KocRegistrationForm
        ] = deps;

        return {
            listId: 'checkout.koc.widgets',
            widgetsDefinition: () => [
                ['kocRegistrationForm', KocRegistrationForm, 'ajaxform']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.koc.widgets' */ 'koc/global/LinkKOCModalBtn').then(d => d.default),
        import(/* webpackChunkName: 'account.koc.widgets' */ 'koc/forms/ajax/KocLookupForm').then(d => d.default)
    ]).then(deps => {
        const [
            LinkKOCModalBtn,
            KocLookupForm
        ] = deps;

        return {
            listId: 'account.koc.widgets',
            widgetsDefinition: () => [
                ['linkKOCModalBtn', LinkKOCModalBtn],
                ['kocLookupForm', KocLookupForm, 'ajaxform']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('wishlist'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.koc.widgets' */ 'koc/wishlist/WishlistMgrKOCExt').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.koc.widgets' */ 'koc/wishlist/WishListItemKOCExt').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.koc.widgets' */ 'koc/store/StorePickupInvokerKOCExt').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.koc.widgets' */ 'koc/wishlist/StoreSubmissionConfirmDialog').then(d => d.default)
    ]).then(deps => {
        const [
            WishlistMgrKOCExt,
            WishListItemKOCExt,
            StorePickupInvokerKOCExt,
            StoreSubmissionConfirmDialog
        ] = deps;

        return {
            listId: 'wishlist.koc.widgets',
            widgetsDefinition: () => [
                ['kocWishlistMgr', WishlistMgrKOCExt, 'wishlistMgr'],
                ['kocWishListItem', WishListItemKOCExt, 'wishListItem'],
                ['storePickupInvokerKoc', StorePickupInvokerKOCExt, 'storePickupInvoker'],
                ['storeSubmissionConfirmDialog', StoreSubmissionConfirmDialog, 'confirmDialog']
            ]
        };
    })
});
